import { Suspense, useEffect, useRef } from "react";
import Header from "../Header/Header";
import { Outlet, useLocation } from "react-router-dom";
import Footer from "../../Footer/Footer";

const CreateAssessLayout: React.FunctionComponent = () => {
  const { pathname } = useLocation();
  const topRef = useRef<HTMLDivElement>(null);
  useEffect(() => {
    if (topRef.current) {
      topRef.current.scrollIntoView({ behavior: "smooth" });
    }
  }, [pathname]);
  return (
    <Suspense>
      <div ref={topRef}>
        <Header />
        <div className="totalQuestions">
          <div className="container-fluid">
            <Outlet />
          </div>
          <Footer />
        </div>
      </div>
    </Suspense>
  );
};

export default CreateAssessLayout;
