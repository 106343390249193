import React, { memo, useEffect, useState } from "react";
import "./LocationSearch.scss";
import { getEntireCustomUrl } from "smartassess-api-lib";

interface LocationSearchProps {
  className: string;
  setCountry: React.Dispatch<React.SetStateAction<string>>;
  setState: React.Dispatch<React.SetStateAction<string>>;
  setCity: React.Dispatch<React.SetStateAction<string>>;
  setLat: React.Dispatch<React.SetStateAction<string>>;
  setLon: React.Dispatch<React.SetStateAction<string>>;
  placeholder: string;
  setFieldValue: (field: string, value: any) => void;
  values: {
    city: string;
    country: string;
    state: string;
  };
  isAddress: string;
}

const LocationSearch: React.FC<LocationSearchProps> = ({
  className,
  setCountry,
  setState,
  setCity,
  setLat,
  setLon,
  placeholder,
  setFieldValue,
  values,
  isAddress,
}) => {
  const [query, setQuery] = useState("");
  const [suggestions, setSuggestions] = useState<any[]>([]);
  useEffect(() => {
    setFieldValue("address", isAddress);
    setQuery(isAddress);
  }, [isAddress]);
  const handleInputChange = async (e: React.ChangeEvent<HTMLInputElement>) => {
    setLat("");
    setLon("");
    setCity("");
    setState("");
    setCountry("");
    values.city = "";
    values.country = "";
    values.state = "";
    setQuery(e.target.value);
    setFieldValue("address", e.target.value);
    try {
      const response: any = await getEntireCustomUrl(
        `https://nominatim.openstreetmap.org/search?format=json&q=${e.target.value}&addressdetails=1`,
      );
      if (response && response.length > 0) {
        setSuggestions(response);
      } else {
        setSuggestions([]);
      }
    } catch (error) {
      console.error("Error fetching suggestions:", error);
    }
  };
  const handleSuggestionClick = (
    lat: string,
    lon: string,
    place: string,
    addressdetails: any,
  ) => {
    setLat(lat);
    setLon(lon);
    setQuery(place);
    setSuggestions([]);
    setCountry(addressdetails?.country || "");
    setState(addressdetails?.state || "");
    setCity(
      addressdetails?.city ||
        addressdetails?.village ||
        addressdetails?.town ||
        "",
    );
    setFieldValue("address", place);
  };

  return (
    <div className="main-div">
      <div>
        <input
          type="text"
          placeholder={placeholder}
          value={query}
          onChange={handleInputChange}
          className={`${className} w-100`}
        />
      </div>
      {suggestions.length > 0 && (
        <div className="suggestion-list">
          {suggestions.length > 0 ? (
            <ul className="unordered-list">
              {suggestions.map((place: any) => (
                <li
                  key={place.place_id}
                  onClick={() =>
                    handleSuggestionClick(
                      place.lat,
                      place.lon,
                      place.display_name,
                      place.address,
                    )
                  }
                  className="list-item"
                >
                  {place.display_name}
                </li>
              ))}
            </ul>
          ) : (
            query && <p className="No-data-found">No place found</p>
          )}
        </div>
      )}
    </div>
  );
};

export default memo(LocationSearch);
