import { handleError } from "../../utils/Common/Constant";
import apiClient from "../Connection/Connection";

// to get assessment types
export const getAssessmentTypes = async () => {
  try {
    return (await apiClient.get("assessmentType/getAssessmentTypes"))?.data;
  } catch (error) {
    handleError(error);
  }
};

// to get Role Centric Render
export const getRoleCentricRender = async (component: string) => {
  try {
    return (
      await apiClient.get(
        `assessmentType/getRoleCentricRender?search=${component}`,
      )
    )?.data?.data;
  } catch (error) {
    handleError(error);
  }
};

// to get assessment skills
export const getIndustrySkill = async (
  industriesId: string,
  searchSkill: string,
  currentPage: number,
  pageSize: number,
) => {
  try {
    return (
      await apiClient.get(
        `industries/getIndustrySkillMapping?industriesId=${industriesId}&search=${searchSkill}&currentPage=${currentPage}&pageSize=${pageSize}`,
      )
    )?.data?.data;
  } catch (error) {
    handleError(error);
  }
};

// to get assessment existing template
export const getPublicAndCompaniesPrivateAssessment = async (
  industriesId: string,
  companyId: string,
  search: string,
  currentPage: number,
  pageSize: number,
) => {
  try {
    return (
      await apiClient.get(
        `assessment/getPublicAndCompaniesPrivateAssessment?industriesId=${industriesId}&companyId=${companyId}&search=${search}&currentPage=${currentPage}&pageSize=${pageSize}`,
      )
    )?.data?.data;
  } catch (error) {
    handleError(error);
  }
};

// to get system preference
export const getFrontEndSystemPreference = async () => {
  try {
    return (await apiClient.get("systemPreference/getSystemPreference"))?.data
      ?.data;
  } catch (error) {
    handleError(error);
  }
};

//  to delete assessment
export const deleteAssessment = async (
  assessmentId: string | number | null,
) => {
  try {
    const res = await apiClient.delete(
      `assessment/deleteDraftedAssessment?assessmentId=${assessmentId}`,
    );
    return res;
  } catch (error) {
    handleError(error);
  }
};

//  for update Assessment Status
export const updateAssessmentStatus = async (data: any) => {
  try {
    const res = await apiClient.put("assessment/updateAssessmentStatus", data);
    return res;
  } catch (error) {
    handleError(error);
  }
};

//  for reopen Deleted Assessment Status
export const reopenDeletedAssessment = async (data: any) => {
  try {
    const res = await apiClient.put("assessment/reopenDeletedAssessment", data);
    return res;
  } catch (error) {
    handleError(error);
  }
};

// company login
export const companyApplicant = async (data: any) => {
  try {
    const res = await apiClient.post("company/companyLogIn", data);
    return res?.data;
  } catch (error) {
    handleError(error);
  }
};

// to get category
export const getIndustrySkillMapping = async (
  categoryIds: any = [],
  search = "",
  filter: any = [],
) => {
  try {
    return (
      await apiClient.get(
        `industries/getIndustrySkillMapping?categoryIds=${JSON.stringify(categoryIds)}&search=${search}&filter=${JSON.stringify(filter)}`,
      )
    )?.data;
  } catch (error) {
    handleError(error);
  }
};

//get otp when signup
export const getOtp = async (data: any) => {
  try {
    const res = await apiClient.post("company/companyUserGetOtp", data);
    return res?.data;
  } catch (error) {
    handleError(error);
  }
};

// verify entered otp
export const verifyGetOtp = async (data: any) => {
  try {
    const res = await apiClient.post("company/companyUserVerifyOtp", data);
    return res?.data;
  } catch (error) {
    handleError(error);
  }
};
//create password api
export const createPassword = async (data: any) => {
  try {
    const res = await apiClient.put(
      "company/GenerateCompanyUserPassword",
      data,
    );
    return res?.data;
  } catch (error) {
    handleError(error);
  }
};

// signup with completed profile api
export const completeProfileApi = async (data: any) => {
  try {
    const res = await apiClient.post("company/completeProfile", data);
    return res?.data;
  } catch (error) {
    handleError(error);
  }
};
// get industries api
export const getIndustries = async (search: string) => {
  try {
    const res = await apiClient.get(
      `industries/getIndustries?search=${search}`,
    );
    return res?.data;
  } catch (error) {
    handleError(error);
  }
};

// to get skill details
export const getSkillDetails = async (skillId: string) => {
  try {
    return (await apiClient.get(`skill/getSkill?skillId=${skillId}`))?.data
      ?.data;
  } catch (error) {
    handleError(error);
  }
};

// to get default skill
export const getDefaultSkill = async (
  industriesId?: string,
  searchSkill?: string,
  currentPage?: number,
  pageSize?: number,
) => {
  try {
    return (
      await apiClient.get(
        `industries/getIndustrySpecificSkill?industryId=${industriesId}&search=${searchSkill}&currentPage=${currentPage}&pageSize=${pageSize}`,
        // `industries/getIndustrySpecificSkill?industryId=${industriesId}`,
      )
    )?.data?.data;
  } catch (error) {
    handleError(error);
  }
};
//get reported text
export const getReportedQuestion = async (
  assessmentId: string,
  questionId: string,
) => {
  try {
    const res = await apiClient.get(
      `company/getReportedQuestion?assessmentId=${assessmentId}&questionId=${questionId}`,
    );
    return res?.data;
  } catch (error) {
    handleError(error);
    return error;
  }
};
//resend otp function
export const resendOtp = async (data: any) => {
  try {
    const res = await apiClient.post("company/companyUserResendOtp", {
      email: data,
    });
    return res?.data;
  } catch (error) {
    handleError(error);
  }
};
//update the result status
export const updateAssessmentApplicantResultStatus = async (data: any) => {
  try {
    const res = await apiClient.put(
      "company/updateAssessmentApplicantResultStatus",
      data,
    );
    return res;
  } catch (error) {
    handleError(error);
  }
};
//get all details of profile
export const getCompanyProfileDetails = async (companyUserId: any) => {
  try {
    const res = await apiClient.get(
      `company/companyProfileDetails?companyUserId=${companyUserId}`,
    );
    return res;
  } catch (error) {
    handleError(error);
  }
};
//notify an applicatnt
export const notifyAnApplicant = async (data: any) => {
  try {
    const res = await apiClient.post("company/notifyAnApplicant", data);
    return res?.data;
  } catch (error) {
    handleError(error);
  }
};
//get all questions reported by applicant
export const getReportedQuestionList = async (
  assessmentId: any,
  applicantId: any,
) => {
  try {
    const res = await apiClient.get(
      `company/companyGetReportedQuestionList?assessmentId=${assessmentId}&applicantId=${applicantId}`,
    );
    return res;
  } catch (error) {
    handleError(error);
  }
};
//  delete temp data
export const deleteAssessmentTempData = async (
  userId: string,
  tempAssessmentUId: string,
) => {
  try {
    const res = await apiClient.delete(
      `assessment/deleteAssessmentTempData?userId=${userId}&tempAssessmentUId=${tempAssessmentUId}`,
    );
    return res;
  } catch (error) {
    handleError(error);
  }
};
