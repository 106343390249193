import React, { useEffect, useState } from "react";
import CorrectnessIcon from "../../utils/Images/CorrectnessIcon";
import VisualAnalysisIcon from "../../utils/Images/VisualAnalysisIcon";
import VoiceAnalysisIcon from "../../utils/Images/VoiceAnalysisIcon";
import { useTranslation } from "react-i18next";
import "./ShowIconsInfinite.scss";

const ShowIconsInfinite = () => {
  const [visibleIndex, setVisibleIndex] = useState(0);
  const { t } = useTranslation();

  useEffect(() => {
    const intervalId = setInterval(() => {
      setVisibleIndex((prevIndex) => (prevIndex + 1) % 3);
    }, 2000); // Adjust timing to match animation duration
    return () => clearInterval(intervalId);
  }, []);
  const renderContent = () => {
    switch (visibleIndex) {
      case 0:
        return (
          <div className="icon-text-sec">
            <CorrectnessIcon color={"#35d4ae"} className="icon fade-in-out" />
            <span className="icon fade-in-out">{t("GENERAL.PROCESSING")}</span>
          </div>
        );
      case 1:
        return (
          <div className="icon-text-sec">
            <VisualAnalysisIcon
              color={"#35d4ae"}
              className="icon fade-in-out"
            />
            <span className="icon fade-in-out">{t("GENERAL.PROCESSING")}</span>
          </div>
        );
      case 2:
        return (
          <div className="icon-text-sec">
            <VoiceAnalysisIcon color={"#35d4ae"} className="icon fade-in-out" />
            <span className="icon fade-in-out">{t("GENERAL.PROCESSING")}</span>
          </div>
        );
      default:
        return null;
    }
  };
  return (
    <div className="iconAnimate">
      <div className="icon-container">
        <div className="icon-text-container">{renderContent()}</div>
      </div>
    </div>
  );
};

export default ShowIconsInfinite;
