import { useState, useRef, useEffect } from "react";
import "./ProfileSettings.scss";
import { useNavigate } from "react-router-dom";
import i18n from "../../../i18nConfig";
import { useTranslation } from "react-i18next";
interface ProfileSettingsProps {
  email: any;
  profileData: any;
}
const ProfileSettings = ({ email, profileData }: ProfileSettingsProps) => {
  const [isPopoverVisible, setPopoverVisible] = useState(false);
  const popoverRef: any = useRef(null);
  const iconRef: any = useRef(null);
  const navigate = useNavigate();
  const { t } = useTranslation();
  const industryId = localStorage.getItem("industryId");
  const currentLanguage = i18n.language;
  const togglePopover = () => {
    setPopoverVisible((prevVisible) => !prevVisible);
  };
  useEffect(() => {
    const handleClickOutside = (event: any) => {
      if (
        popoverRef.current &&
        !popoverRef.current.contains(event.target) &&
        iconRef.current &&
        !iconRef.current.contains(event.target)
      ) {
        setPopoverVisible(false);
      }
    };
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);
  return (
    <div className="profile-settings">
      <div className="logo-place" ref={iconRef} onClick={togglePopover}>
        {profileData?.logo ? (
          <img
            src={profileData?.logo}
            alt="Company Logo"
            className="company-logo"
          />
        ) : (
          <div className="profile-icon company-logo">
            {email?.charAt(0).toLocaleUpperCase()}
          </div>
        )}
      </div>
      {isPopoverVisible && (
        <div className="popover" ref={popoverRef}>
          <div className="popover-header">
            <div className="logo-name-sec">
              {profileData?.logo ? (
                <img
                  src={profileData?.logo}
                  alt="Company Logo"
                  className="company-logo"
                />
              ) : (
                <div className="profile-icon company-logo">
                  {email?.charAt(0).toLocaleUpperCase()}
                </div>
              )}
              <div className="company-name">
                <strong>
                  {profileData?.companyName ? profileData?.companyName : "-"}
                </strong>
                <div className="email-date-section">
                  <span>{profileData?.email ?? email}</span>
                </div>
              </div>
            </div>
          </div>
          <div className="popover-body">
            <div
              className="popover-item"
              onClick={() =>
                industryId === "null"
                  ? navigate(`/${currentLanguage}/assessment/create-profile`, {
                      state: { editProfile: false },
                    })
                  : (setPopoverVisible(false),
                    navigate(`/${currentLanguage}/assessment/create-profile`, {
                      state: { editProfile: true },
                    }))
              }
            >
              {t("GENERAL.PROFILE")}
            </div>
            <div className="popover-item">{t("GENERAL.NOTIFICATION")}</div>
            <div className="popover-item">{t("GENERAL.REFER_EARN")}</div>
            <div className="popover-item">{t("GENERAL.SETTINGS")}</div>
          </div>
          <div className="popover-footer">
            <hr />
            <div
              className="popover-item"
              onClick={() => {
                localStorage.removeItem("companyToken");
                localStorage.removeItem("companyId");
                localStorage.removeItem("industryId");
                localStorage.removeItem("tempUniqueId");
                localStorage.removeItem("mediaPath");
                localStorage.removeItem("companyUserId");
                localStorage.removeItem("email");
                localStorage.removeItem("companyAddress");
                sessionStorage.clear();
                navigate("/");
              }}
            >
              {t("GENERAL.LOGOUT")}
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default ProfileSettings;
