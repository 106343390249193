import { useTranslation } from "react-i18next";
import { AssessmentCheckboxRadio } from "ui-components";
import "./AdvanceSetting.scss";
interface JobTypeSelector {
  setAdvanceSettingData: any;
  advanceSettingData: any;
}
const JobTypeSelector: React.FC<JobTypeSelector> = ({
  setAdvanceSettingData,
  advanceSettingData,
}) => {
  const { t } = useTranslation();
  return (
    <div className="jobType">
      <span>{t("CREATE_ASSESSMENT.JOBTYPE")}</span>
      <div className="jobTypeRow mt-3">
        <div
          className="d-flex jobTypeCursorPointer rtlGap"
          style={{
            pointerEvents:
              advanceSettingData && advanceSettingData?.disable
                ? "none"
                : "auto",
          }}
          onClick={() => {
            setAdvanceSettingData((prev: any) => {
              const updatedData = { ...prev, jobType: "fullTime" };
              sessionStorage.setItem(
                "advanceSettingData",
                JSON.stringify(updatedData),
              );
              return updatedData;
            });
          }}
        >
          <AssessmentCheckboxRadio
            name="fullTime"
            type="radio"
            checked={advanceSettingData?.jobType == "fullTime"}
          />
          <p className="radioCheckLabel">{t("CREATE_ASSESSMENT.FULLTIME")}</p>
        </div>
        <div
          className="d-flex jobTypeCursorPointer rtlGap"
          style={{
            pointerEvents:
              advanceSettingData && advanceSettingData?.disable
                ? "none"
                : "auto",
          }}
          onClick={() => {
            setAdvanceSettingData((prev: any) => {
              const updatedData = { ...prev, jobType: "partTime" };
              sessionStorage.setItem(
                "advanceSettingData",
                JSON.stringify(updatedData),
              );
              return updatedData;
            });
          }}
        >
          <AssessmentCheckboxRadio
            name="partTime"
            type="radio"
            checked={advanceSettingData?.jobType == "partTime"}
          />
          <p className="radioCheckLabel">{t("CREATE_ASSESSMENT.PARTTIME")}</p>
        </div>
        <div
          className="d-flex jobTypeCursorPointer rtlGap"
          style={{
            pointerEvents:
              advanceSettingData && advanceSettingData?.disable
                ? "none"
                : "auto",
          }}
          onClick={() => {
            setAdvanceSettingData((prev: any) => {
              const updatedData = { ...prev, jobType: "contract" };
              sessionStorage.setItem(
                "advanceSettingData",
                JSON.stringify(updatedData),
              );
              return updatedData;
            });
          }}
        >
          <AssessmentCheckboxRadio
            name="contract"
            type="radio"
            checked={advanceSettingData?.jobType == "contract"}
          />
          <p className="radioCheckLabel">{t("CREATE_ASSESSMENT.CONTRACT")}</p>
        </div>
        <div
          className="d-flex jobTypeCursorPointer rtlGap"
          style={{
            pointerEvents:
              advanceSettingData && advanceSettingData?.disable
                ? "none"
                : "auto",
          }}
          onClick={() => {
            setAdvanceSettingData((prev: any) => {
              const updatedData = { ...prev, jobType: "internship" };
              sessionStorage.setItem(
                "advanceSettingData",
                JSON.stringify(updatedData),
              );
              return updatedData;
            });
          }}
        >
          <AssessmentCheckboxRadio
            name="internship"
            type="radio"
            checked={advanceSettingData?.jobType == "internship"}
          />
          <p className="radioCheckLabel">{t("CREATE_ASSESSMENT.INTERNSHIP")}</p>
        </div>
      </div>
    </div>
  );
};

export default JobTypeSelector;
