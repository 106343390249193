import "./TemplateBottomRightSide.scss";
import { useTranslation } from "react-i18next";
import { setSessionStorageItem } from "../../../../../utils/SessionStorageMethod/setSessionStorageItem";
import {
  EssayTypeQuestion,
  MultiTypeQuestion,
  SingleTypeQuestion,
} from "ui-components";

interface Answer {
  label: string;
  value: boolean;
}

interface SingleQuestion {
  question: string;
  answerSet: Answer[];
  type: string;
  timeLimit?: undefined | number | string;
}

interface MultipleQuestion {
  question: string;
  answerSet: Answer[];
  type: string;
  timeLimit?: undefined | number | string;
}

interface EssayQuestion {
  question: string;
  answer: string;
  type: string;
  timeLimit?: undefined | number | string;
}

interface AnswerOptionState {
  Single: {
    id: string;
    label: string;
    selected: boolean;
    timeLimit?: undefined | number | string;
  }[];
  Multiple: {
    id: string;
    label: string;
    selected: boolean;
    timeLimit?: undefined | number | string;
  }[];
  Essay: string;
  Video: string;
}

export type QuestionTypeData =
  | SingleQuestion
  | MultipleQuestion
  | EssayQuestion;

export interface TemplateBottomRightSideProps {
  createdQuestionData: QuestionTypeData[] | null;
  setCreatedQuestionData: any;
  attachedQuestionImage: any;
  setAttachedQuestionImage: any;
  setTextEditorQuestion: any;
  answerOptionSetState: any;
  defaultAnswerOption: AnswerOptionState;
  setSelectedType: any;
  setTimeLimit: any;
  setIsQuestionAnsEdited: any;
}

const TemplateBottomRightSide: React.FunctionComponent<
  TemplateBottomRightSideProps
> = ({
  createdQuestionData,
  setCreatedQuestionData,
  setAttachedQuestionImage,
  setTextEditorQuestion,
  answerOptionSetState,
  defaultAnswerOption,
  setSelectedType,
  setTimeLimit,
  setIsQuestionAnsEdited,
}) => {
  const { t } = useTranslation();
  const deleteQuestion = (getDeleteQuestionDetail: any) => {
    setSessionStorageItem(
      "createQuestionData",
      createdQuestionData?.filter(
        (item: any) => item?.id !== getDeleteQuestionDetail?.id,
      ),
    );
    setCreatedQuestionData((prevArray: any) => {
      const newArray = prevArray.filter(
        (item: any) => item.id !== getDeleteQuestionDetail.id,
      );
      return [...newArray];
    });
  };

  const editQuestion = (getDeleteQuestionDetail: any) => {
    setIsQuestionAnsEdited(true);
    setTextEditorQuestion(getDeleteQuestionDetail.question);
    const copiedObject = { ...defaultAnswerOption };
    for (const key in copiedObject) {
      if (key == getDeleteQuestionDetail.type) {
        (copiedObject as any)[key] = getDeleteQuestionDetail.answerSet;
      }
    }
    setAttachedQuestionImage(getDeleteQuestionDetail?.image);
    answerOptionSetState(copiedObject);
    setTimeLimit(getDeleteQuestionDetail.timeLimit);
    setSelectedType({
      id: getDeleteQuestionDetail.id,
      type: getDeleteQuestionDetail.type,
    });
  };

  if (!createdQuestionData) {
    return null; // Render nothing if the preview is null
  }
  return (
    <div className="customTotalQuestions">
      {createdQuestionData?.map((data, index) => {
        const questionSeries = `Q. ${index + 1}`;
        switch (data?.type?.toLowerCase()) {
          case "single":
            return (
              <SingleTypeQuestion
                key={index}
                isAccordion={false}
                type={t("GENERAL.SINGLE")}
                timeLabel={t("GENERAL.TIME")}
                timeValue={`${data.timeLimit} ${t("GENERAL.MINS")}`}
                data={data}
                questionSeries={questionSeries}
                onEditClick={() => {
                  editQuestion(data);
                }}
                onDeleteClick={() => {
                  deleteQuestion(data);
                }}
                answerLabel={t("GENERAL.ANSWER")}
              />
            );
          case "multiple":
            return (
              <MultiTypeQuestion
                key={index}
                isAccordion={false}
                type={t("GENERAL.MULTIPLE")}
                timeLabel={t("GENERAL.TIME")}
                timeValue={`${data.timeLimit} ${t("GENERAL.MINS")}`}
                data={data}
                questionSeries={questionSeries}
                onEditClick={() => {
                  editQuestion(data);
                }}
                onDeleteClick={() => {
                  deleteQuestion(data);
                }}
                answerLabel={t("GENERAL.ANSWER")}
              />
            );
          case "essay":
            return (
              <EssayTypeQuestion
                key={index}
                isAccordion={false}
                type={t("GENERAL.ESSAY")}
                timeLabel={t("GENERAL.TIME")}
                timeValue={`${data.timeLimit} ${t("GENERAL.MINS")}`}
                data={data}
                questionSeries={questionSeries}
                onEditClick={() => {
                  editQuestion(data);
                }}
                onDeleteClick={() => {
                  deleteQuestion(data);
                }}
                answerLabel={t("GENERAL.ANSWER")}
              />
            );
          // EssayTypeQuestion component is also used for video as well in future will change if we needed.
          case "video":
            return (
              <EssayTypeQuestion
                key={index}
                isAccordion={false}
                type={t("GENERAL.VIDEO")}
                timeLabel={t("GENERAL.TIME")}
                timeValue={`${data.timeLimit} ${t("GENERAL.MINS")}`}
                data={data}
                questionSeries={questionSeries}
                onEditClick={() => {
                  editQuestion(data);
                }}
                onDeleteClick={() => {
                  deleteQuestion(data);
                }}
                answerLabel={t("GENERAL.ANSWER")}
              />
            );
          default:
            return null; // Handle unknown type or skip rendering
        }
      })}
    </div>
  );
};

export default TemplateBottomRightSide;
