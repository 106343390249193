import { useTranslation } from "react-i18next";
import "./ReviewEvalution.scss";
import { AssessmentCheckboxRadio } from "ui-components";
import { useState } from "react";
import { setSessionStorageItem } from "../../../../../utils/SessionStorageMethod/setSessionStorageItem";
import { getSessionStorageItem } from "../../../../../utils/SessionStorageMethod/getSessionStorageItem";
import SmartAssessLogo from "../../../../../utils/Images/SmartAssessLogo";
import ManualReviewLogo from "../../../../../utils/Images/ManualReviewLogo";
import VisualAnalysisIcon from "../../../../../utils/Images/VisualAnalysisIcon";
import VoiceAnalysisIcon from "../../../../../utils/Images/VoiceAnalysisIcon";
import CorrectnessIcon from "../../../../../utils/Images/CorrectnessIcon";

const ReviewEvalution: React.FunctionComponent = () => {
  const { t } = useTranslation();

  const [evaluation, setEvaluation] = useState<any>(
    getSessionStorageItem("evaluation") ?? [
      { type: "smartAi", aiAnalysis: false },
      { type: "smartAi", visualAnalysis: false },
      { type: "smartAi", audioAnalysis: false },
      { type: "manual", attributes: true },
    ],
  );

  const handleEvaluation = (key: string, value: boolean) => {
    setEvaluation((prevEvaluation: any) => {
      let updateEvaluation: any = prevEvaluation.map((item: any) => {
        if (Object.prototype.hasOwnProperty.call(item, key)) {
          return { ...item, [key]: value };
        }
        return item;
      });

      // If aiAnalysis is set to false, set all other evaluations to false
      if (key === "aiAnalysis" && !value) {
        updateEvaluation = updateEvaluation.map((item: any) => {
          if (Object.prototype.hasOwnProperty.call(item, "aiAnalysis")) {
            return {
              ...item,
              aiAnalysis: false,
              visualAnalysis: false,
              audioAnalysis: false,
            };
          }
          return item;
        });
      }

      setSessionStorageItem("evaluation", updateEvaluation);
      return updateEvaluation;
    });
  };

  const handleCheckboxClick = (key: keyof (typeof evaluation)[number]) => {
    const value = !evaluation.find((item: any) =>
      Object.prototype.hasOwnProperty.call(item, key),
    )?.[key];
    handleEvaluation(key as string, value);
  };

  return (
    <div className="evolutionSec">
      <div className="horizontalLine"></div>
      <h6>{t("GENERAL.EVALUATION")}</h6>

      <div className="evolutionAdd">
        <div className="evolutionChecked defaultEvolutionChecked">
          <AssessmentCheckboxRadio
            type="checkbox"
            checked={
              evaluation.find((item: any) =>
                Object.prototype.hasOwnProperty.call(item, "attributes"),
              )?.attributes
            }
          />
          <p>
            <ManualReviewLogo color={"#35d4ae"} /> &nbsp;{" "}
            {t("GENERAL.MANUAL_REVIEW")}
          </p>
        </div>
        <div
          className="evolutionChecked"
          onClick={() => handleCheckboxClick("aiAnalysis")}
        >
          <AssessmentCheckboxRadio
            type="checkbox"
            checked={
              evaluation.find((item: any) =>
                Object.prototype.hasOwnProperty.call(item, "aiAnalysis"),
              )?.aiAnalysis
            }
          />
          <p>
            <SmartAssessLogo
              color={evaluation[0].aiAnalysis ? "#35d4ae" : "#B3B3B3"}
            />
            &nbsp;&nbsp;&nbsp;{t("GENERAL.SMARTASSESS_AI")}
          </p>
        </div>

        <div className="smartSubEvaluation">
          <div className="evolutionChecked defaultEvolutionChecked">
            <AssessmentCheckboxRadio
              type="checkbox"
              additionalProps={{
                disabled: !evaluation.some((item: any) => item.aiAnalysis),
              }}
              checked={
                evaluation.find((item: any) =>
                  Object.prototype.hasOwnProperty.call(item, "aiAnalysis"),
                )?.aiAnalysis
              }
            />
            <p>
              <CorrectnessIcon
                color={
                  evaluation.some((item: any) => item.aiAnalysis)
                    ? "#35d4ae"
                    : "#B3B3B3"
                }
              />
              &nbsp;&nbsp; {t("RESPONSE_LIST.AI_ANALYSIS")}
            </p>
          </div>

          <div
            className="evolutionChecked"
            onClick={() => {
              if (evaluation.some((item: any) => item.aiAnalysis))
                handleCheckboxClick("visualAnalysis");
            }}
          >
            <AssessmentCheckboxRadio
              type="checkbox"
              additionalProps={{
                disabled: !evaluation.some((item: any) => item.aiAnalysis),
              }}
              checked={
                evaluation.find((item: any) =>
                  Object.prototype.hasOwnProperty.call(item, "visualAnalysis"),
                )?.visualAnalysis
              }
            />
            <p>
              <VisualAnalysisIcon
                color={
                  evaluation.find(
                    (item: any) => item.visualAnalysis === false,
                  ) || !evaluation.some((item: any) => item.visualAnalysis)
                    ? "#B3B3B3"
                    : "#35d4ae"
                }
              />
              &nbsp;&nbsp; {t("CREATE_ASSESSMENT.LABEL_VISUAL_ANALYSIS")}
            </p>
          </div>

          <div
            className="evolutionChecked"
            onClick={() => {
              if (evaluation.some((item: any) => item.aiAnalysis))
                handleCheckboxClick("audioAnalysis");
            }}
          >
            <AssessmentCheckboxRadio
              type="checkbox"
              additionalProps={{
                disabled: !evaluation.some((item: any) => item.aiAnalysis),
              }}
              checked={
                evaluation.find((item: any) =>
                  Object.prototype.hasOwnProperty.call(item, "audioAnalysis"),
                )?.audioAnalysis
              }
            />
            <p>
              <VoiceAnalysisIcon
                color={
                  evaluation.find(
                    (item: any) => item.audioAnalysis === false,
                  ) || !evaluation.some((item: any) => item.audioAnalysis)
                    ? "#B3B3B3"
                    : "#35d4ae"
                }
              />
              &nbsp;&nbsp; {t("CREATE_ASSESSMENT.LABEL_VOICE_ANALYSIS")}
            </p>
          </div>
        </div>
      </div>
      <div className="horizontalLine"></div>
    </div>
  );
};

export default ReviewEvalution;
