import "./CreateAssesmentResponseList.scss";
import EmailIcon from "../../../utils/Images/EmailIcon";
import PhoneIcon from "../../../utils/Images/PhoneIcon";
import { useEffect, useState, useRef, useContext } from "react";
import { useTranslation } from "react-i18next";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import {
  manageDuplicateSkills,
  convertDate,
  handleScroll,
  removeDuplicatesBySkillId,
  copyToClipboard,
} from "../../../utils/Common/Constant";
import BackArrow from "../../../utils/Images/BackArrow";
import {
  AssessmentButton,
  Bullets,
  InputField,
  TypeSelect,
  showToast,
} from "ui-components";
import QuestionResponsePopup from "./QuestionResponsePopup/QuestionResponsePopup";
import UserImage from "../../../utils/Images/UserImage";
import {
  addEvaluationComment,
  companyGetApplicantAssessmentResponse,
} from "../../../service/CreatedAssessmentListAndReview/CreatedAssessmentListAndReview";
import DataNotAvailable from "../../../utils/DataNotFound/DataNotAvailable";
import EvaluationTypes from "./EvaluationTypes/EvaluationTypes";
import { systemPreferenceContext } from "../../../context/SystemPreference/SystemPreference";
import AiQuestionEvaluation from "./EvaluationTypes/AiQuestionEvaluation";
import SkillScoreCard from "../../../components/SkillScoreCard/SkillScoreCard";
import PreviousArrow from "../../../utils/Images/PreviousArrow";
import NextArrow from "../../../utils/Images/NextArrow";
import Voice_VisualAnalysis from "./EvaluationTypes/Voice_VisualAnalysis";
import {
  notifyAnApplicant,
  updateAssessmentApplicantResultStatus,
} from "../../../service/Scope/Scope";
import CopyVector from "../../../utils/Images/CopyVector";
import ReportedQuestionsList from "./ReportedQuestionsList";
import i18n from "../../../i18nConfig";
import BarGraph from "../../../components/BarGraph/BarGraph";
import ShowIconsInfinite from "../../../components/AnimatedLoader/ShowIconsInfinite";
import NotifyConfirmPopup from "./NotifyConfirmPopup/NotifyConfirmPopup";
import InfoIcon from "../../../utils/Images/InfoIcon";
// import SelectedIcon from "../../../utils/Images/SelectedIcon";
// import RejectedIcon from "../../../utils/Images/RejectedIcon";
// const buttons = [
//   { id: "All", label: "RESPONSE_LIST.ALL" },
//   { id: "Ai", label: "RESPONSE_LIST.AI" },
//   { id: "Coding", label: "RESPONSE_LIST.CODING" },
//   { id: "Custom", label: "RESPONSE_LIST.CUSTOM" },
// ];

function CreatedAssessmentList() {
  const { t } = useTranslation();
  const currentLanguage = i18n.language;
  const navigate = useNavigate();
  const location = useLocation();
  const applicantId = new URLSearchParams(location.search).get("aplid");
  const { assessmentIdParam } = useParams();
  const { preferenceData } = useContext(systemPreferenceContext);
  const [responseDropdownStatus, setResponseDropdownStatus] = useState<any>([]);
  const [showQuestion, setShowQuestion] = useState(() => {
    const isPopUpOpen = new URLSearchParams(location.search).get("openPopup");
    return isPopUpOpen ? JSON.parse(isPopUpOpen) : false;
  });
  const isPopupInd = new URLSearchParams(location.search).get("ind");
  const [commentText, setCommentText] = useState<string>("");
  const [selectedButton, setSelectedButton] = useState<string | string[]>(
    "All",
  );
  const [questionType, setQuestionType] = useState<any>([]);
  const [questionData, setQuestionData] = useState<any>([]);
  const [isScoreUpdated, setIsScoreUpdated] = useState<any>();
  const [isNotifyPopupVisible, setIsNotifyPopupVisible] = useState(() => {
    const isNotifyPopupOpen = new URLSearchParams(location.search).get(
      "notifyConfirmation",
    );
    return isNotifyPopupOpen ? JSON.parse(isNotifyPopupOpen) : false;
  });
  const [assessmentApplicantDetails, setAssessmentApplicantDetails] =
    useState<any>();
  const [assessmentSkill, setAssessmentSkill] = useState([]);
  // const [selectedImage, setSelectedImage] = useState("");
  const [questionRapperData, setQuestionRapperData] = useState({
    index: 0,
    questionData: "",
    questionNo: null,
  });
  const [
    assessmentApplicantSkillExperience,
    setAssessmentApplicantSkillExperience,
  ] = useState<any>(null);
  const [totalAssessmentQuestions, setTotalAssessmentQuestions] = useState<
    number | undefined | null
  >(0);
  let scrollRef: React.MutableRefObject<any> | null = useRef<any>(null);
  const [activeQueID, setactiveQueId] = useState<string>("");
  const [evaluationTypesObj, setEvaluationTypesObj] = useState<any>(null);
  const [skillIdFromSelect, setSkillIdFromSelect] = useState<any>("");
  const [evaluationSummary, setEvaluationSummary] = useState<any>(null);
  const [resultStatus, setResultStatus] = useState("");
  const [skillScore, setSkillScore] = useState<any>([]);
  const [categorieScore, setCategorieScore] = useState<any>([]);
  const skillsCardRef = useRef<any>(null);
  const [notifyBtnDisable, setNotifyBtnDisable] = useState(false);
  const urlRef = useRef(null);
  const companyUserId: any = localStorage.getItem("companyUserId");
  useEffect(() => {
    (async () => {
      const assessmentRes: any = await companyGetApplicantAssessmentResponse(
        applicantId,
        assessmentIdParam,
        selectedButton,
      );
      if (assessmentRes?.data?.status === 200) {
        setQuestionData(assessmentRes?.data?.data?.data);
        setAssessmentSkill(assessmentRes?.data?.data?.assessmentSkills);
        setAssessmentApplicantSkillExperience(
          assessmentRes?.data?.data?.assessmentApplicantSkillExperience,
        );
        setTotalAssessmentQuestions(assessmentRes?.data?.data?.count);
        setAssessmentApplicantDetails(
          assessmentRes?.data?.data?.ApplicantAssessment,
        );
        setCommentText(
          assessmentRes?.data?.data?.ApplicantAssessment?.finalComment,
        );
        setResultStatus(
          assessmentRes?.data?.data?.ApplicantAssessment?.resultStatus,
        );
        setEvaluationSummary(
          assessmentRes?.data?.data?.ApplicantAssessment?.evaluationSummary,
        );
        const smartAiObj =
          assessmentRes?.data?.data?.ApplicantAssessment?.evaluationTypes.filter(
            (item: any) => item.type === "smartAi",
          );
        setEvaluationTypesObj(smartAiObj[0]);
        isPopupInd !== null &&
          setQuestionRapperData({
            index: Number(isPopupInd),
            questionData: assessmentRes?.data?.data?.data[Number(isPopupInd)],
            questionNo:
              assessmentRes?.data?.data?.data[Number(isPopupInd)]
                ?.questionSequence,
          });
        const uniqueSkillsSet = removeDuplicatesBySkillId(
          assessmentRes?.data?.data?.tabScore,
        ).filter(
          (item: { totalQuestions: number }) => item.totalQuestions !== 0,
        );
        setSkillScore(uniqueSkillsSet);
        setCategorieScore(assessmentRes?.data?.data?.categoryScore);
      } else {
        if (assessmentRes?.status === 409) {
          navigate(`/${currentLanguage}/404-not-found`);
        }
      }
    })();
  }, [applicantId, selectedButton, isPopupInd, isScoreUpdated]);

  useEffect(() => {
    if (preferenceData?.responseDropdownStatusLabel !== undefined) {
      const statusArray = Object.keys(
        preferenceData?.responseDropdownStatusLabel,
      ).map((key) => {
        const iconKey = `${key}Icon`;
        return {
          label: preferenceData?.responseDropdownStatusLabel[key],
          skillIcon: preferenceData?.responseDropdownStatusIcons[iconKey],
          value: preferenceData?.responseDropdownStatusLabel[key],
        };
      });
      if (resultStatus) {
        const reviewPendingObj = statusArray.find((item) => {
          if (resultStatus === "Hold") {
            setResultStatus("On Hold");
            return item.label === "On Hold";
          } else if (resultStatus === "Rejected") {
            setResultStatus("Not Selected");
            return item.label === "Not Selected";
          } else {
            return item.label === resultStatus;
          }
        });

        setSkillIdFromSelect(reviewPendingObj);
      } else {
        // Find the object with label "Review Pending"
        const reviewPendingObj = statusArray.find(
          (item) => item.label === "Review Pending",
        );
        setSkillIdFromSelect(reviewPendingObj);
      }
      setResponseDropdownStatus(statusArray);
    }
  }, [preferenceData?.responseDropdownStatusLabel, assessmentApplicantDetails]);
  // to handle comment text
  const handleCommentText = (id: any, value: any) => {
    setCommentText(value);
  };
  const handleAddEvaluationComment = async (isStatusSelect = false) => {
    const addEvaluationObj = {
      assessmentId: assessmentIdParam,
      evaluationComment: commentText,
      companyUserId: localStorage.getItem("companyUserId"),
      applicantId,
    };
    const addEvaluationRes = await addEvaluationComment(addEvaluationObj);
    if (addEvaluationRes?.data?.status === 200) {
      isStatusSelect
        ? showToast(t("RESPONSE_LIST.EVALUATION_STATUS_UPDATED"), "success")
        : showToast(t("RESPONSE_LIST.EVALUATION_COMMENT_SAVED"), "success");
    }
  };
  const handleUpdateResultStatus = async (getValue?: any) => {
    if (getValue === "Not Selected") {
      getValue = "Rejected";
    } else if (getValue === "On Hold") {
      getValue = "Hold";
    }
    const data = {
      assessmentId: assessmentIdParam,
      applicantId,
      companyUserId: localStorage.getItem("companyUserId"),
      resultStatus: getValue || skillIdFromSelect?.label || skillIdFromSelect,
    };
    try {
      const res = await updateAssessmentApplicantResultStatus(data);
      showToast(res?.data?.customMessage, "success");
    } catch (error) {
      console.error(error);
    }
  };
  useEffect(() => {
    if (activeQueID && showQuestion === false) {
      if (scrollRef?.current) {
        const questionContainer = scrollRef?.current;
        const selectedQuestion =
          questionContainer.querySelector(".activeScroll");
        if (selectedQuestion) {
          selectedQuestion.scrollIntoView({ behavior: "instant" });
        }
      }
    }
    // clean up
    () => {
      scrollRef = null;
      setactiveQueId("");
    };
  }, [showQuestion]);
  // function add class activeScroll dynamically on question list item on click
  const scrollAppend = (quesId: string, ID: string) => {
    return quesId === ID ? "activeScroll" : "";
  };
  const assessmentDetails = {
    totalTime: assessmentApplicantDetails?.totalTime,
    assessmentId: assessmentIdParam,
  };
  const handleResponseListClick = (item: any, index: any) => {
    if (item?.status === "answered") {
      setactiveQueId(item.questionId);
      const currentUrl = window.location.href;
      const url = new URL(currentUrl);
      url.searchParams.set("openPopup", "true");
      url.searchParams.set("ind", index);
      history.replaceState({}, "", url.toString());
      setShowQuestion(true);
      setQuestionRapperData({
        index: index,
        questionData: item,
        questionNo: item?.questionSequence,
      });
    } else {
      showToast(t("ERROR.SKIPPED_QUESTION"));
    }
  };
  const onScoreCardClick = (item: any) => {
    if (item?.key === "All" || item?.key === "Custom") {
      setSelectedButton(item?.key);
      setQuestionType([]);
    } else {
      setSelectedButton((prevSelected) => {
        const currentArray = Array.isArray(prevSelected) ? prevSelected : [];
        if (currentArray.includes(item?.skillId)) {
          const newArray = currentArray.filter(
            (skillId) => skillId !== item?.skillId,
          );
          return newArray.length === 0 ? "All" : newArray;
        } else {
          const newArray = [...currentArray, item?.skillId];
          return prevSelected === "All" ? newArray : newArray;
        }
      });

      setQuestionType((prevSelected: any) => {
        const currentArray = Array.isArray(prevSelected) ? prevSelected : [];
        if (currentArray.includes(item?.questionType)) {
          const newArray = currentArray.filter(
            (questionType) => questionType !== item?.questionType,
          );
          return newArray.length === 0 ? "All" : newArray;
        } else {
          const newArray = [...currentArray, item?.questionType];
          return prevSelected === "All" ? newArray : newArray;
        }
      });
    }
  };
  //this function used to open notify confirmation popup
  const onNotifyClick = () => {
    const currentUrl = window.location.href;
    const url = new URL(currentUrl);
    url.searchParams.set("notifyConfirmation", "true");
    history.replaceState({}, "", url.toString());
    setIsNotifyPopupVisible(true);
  };
  //this function used to notify applicant
  const notifyAnApplicantApi = async () => {
    setNotifyBtnDisable(true);
    const data = {
      companyUserId,
      assessmentId: assessmentIdParam,
      applicantId,
    };
    try {
      const res = await notifyAnApplicant(data);
      if (res?.status === 200) {
        const currentUrl = window.location.href;
        const url = new URL(currentUrl);
        url.searchParams.set("notifyConfirmation", "false");
        history.replaceState({}, "", url.toString());
        const isPopUpOpen: any = new URLSearchParams(location.search).get(
          "notifyConfirmation",
        );
        setIsNotifyPopupVisible(JSON.parse(isPopUpOpen));
        showToast(res?.customMessage, "success");
        setIsScoreUpdated(Math.random() * 10);
      }
    } catch (error) {
      console.error(error);
    } finally {
      setNotifyBtnDisable(false);
    }
  };
  const filterAiConfidence = (evaluationResult: any) => {
    const results = evaluationResult
      .map((item: any) => item)
      .filter((item: any) =>
        item?.evaluationType === "smartAi_all" ||
        item.evaluationType === "smart_correctness_visual" ||
        item.evaluationType === "smart_correctness_audio" ||
        item.evaluationType === "smart_correctness"
          ? item
          : null,
      );
    const aiConfidence = results[0]?.evaluation?.gemini?.result_confidence;
    return aiConfidence;
  };
  return (
    <>
      <div className="CreatedAssessmentResponseList">
        <div className="backArrow" onClick={() => window.history.back()}>
          <BackArrow />
        </div>
        <div className="languageSec d-flex justify-content-between">
          <div className=" statusHeading">
            <div className="d-flex">
              <h2 ref={urlRef}>{assessmentApplicantDetails?.title}</h2>
              {assessmentApplicantDetails?.slugUrl && (
                <div
                  className="listCopyIcon"
                  onClick={() =>
                    copyToClipboard(
                      urlRef,
                      assessmentApplicantDetails?.slugUrl,
                      t("INVITE.COPY_SUCCESS"),
                    )
                  }
                  title={t("GENERAL_PUBLIC_URL")}
                >
                  <CopyVector color="#35D4AE" />
                </div>
              )}
            </div>
            <div className="bulletSec mt-3 skillsSecWidth">
              {(manageDuplicateSkills(assessmentSkill) || []).map(
                (skillList: any) => (
                  <Bullets
                    key={Math.random()}
                    skillName={skillList?.skillName ?? ""}
                  />
                ),
              )}
            </div>
          </div>
          <div
            className={`StatusDropDown ${notifyBtnDisable && "addDisableCss"}`}
          >
            {/* <img src={skillIdFromSelect?.skillIcon} /> */}
            {!assessmentApplicantDetails?.isNotified ? (
              !assessmentApplicantDetails?.evaluationPending ? (
                <TypeSelect
                  options={responseDropdownStatus}
                  value={skillIdFromSelect?.label || skillIdFromSelect}
                  onChange={(getValue: any) => {
                    setSkillIdFromSelect(getValue);
                    // handleAddEvaluationComment(getValue, true);
                    handleUpdateResultStatus(getValue);
                    if (getValue?.label === "Not Selected") {
                      setResultStatus("Rejected");
                    } else if (getValue?.label === "On Hold") {
                      setResultStatus("hold");
                    } else {
                      setResultStatus(getValue?.label);
                    }
                  }}
                  loadingText={t("GENERAL.LOADING")}
                  placeholder={t("GENERAL.STATUS")}
                  className="typeSelectDropdown"
                  iconName="skillIcon"
                />
              ) : (
                <div className="resultStatusText noBorder">
                  <h4 className="clrGreen iconAnimate">
                    <ShowIconsInfinite />
                  </h4>
                </div>
              )
            ) : (
              <div className="resultStatusText">
                {/* {assessmentApplicantDetails?.resultStatus == "Selected" ? <SelectedIcon /> : <RejectedIcon />} */}
                <span
                  className={`${assessmentApplicantDetails?.resultStatus === "Selected" ? "clrGreen" : "clrOrange"}`}
                >
                  {assessmentApplicantDetails?.resultStatus
                    ? assessmentApplicantDetails?.resultStatus
                    : ""}
                </span>
              </div>
            )}
            {!assessmentApplicantDetails?.isNotified &&
              (resultStatus === "Selected" ||
                resultStatus === "Not Selected" ||
                skillIdFromSelect === "Selected" ||
                skillIdFromSelect === "Not Selected") && (
                <div className="notifyBtnSec">
                  <AssessmentButton
                    className={`notifyBtn lightBtn ${notifyBtnDisable && "notifyBtnDisable"}`}
                    disabled={notifyBtnDisable}
                    onClick={() => {
                      // notifyAnApplicantApi();
                      onNotifyClick();
                    }}
                  >
                    {t("GENERAL.NOTIFY_TEXT")}
                  </AssessmentButton>
                </div>
              )}
          </div>
        </div>
        <div className="evaluationTypes d-flex flex-column">
          <h4 className="evaluationCriteria">
            {t("RESPONSE_LIST.EVALUATION_CRITERIA")}
          </h4>
          <div className="evaluationAnalysis mt-2 mb-4">
            <div className="row">
              <EvaluationTypes evaluationTypesObj={evaluationTypesObj} t={t} />
            </div>
          </div>
        </div>
        <div className="assessProfileSection">
          <div className="profileSection">
            <div className="assessUserSec">
              <div className="userImageBorder">
                {assessmentApplicantDetails?.applicantImage === null ? (
                  <UserImage />
                ) : (
                  <img
                    src={assessmentApplicantDetails?.applicantImage}
                    alt=""
                  />
                )}
              </div>
              <div>
                <h4>{assessmentApplicantDetails?.displayName ?? "-"}</h4>
                <div className="d-flex socialIconSec">
                  <EmailIcon />
                  <p>{assessmentApplicantDetails?.email ?? "-"}</p>
                </div>
                <div className="d-flex socialIconSec">
                  <PhoneIcon />
                  <p>{assessmentApplicantDetails?.mobile ?? "-"}</p>
                </div>
              </div>
            </div>
            <div className="scoreRemarkSec">
              <div className="assessUserScore">
                <div className="assessOverallScoreSec">
                  <p>{t("GENERAL.CUTOFF")}</p>
                  <h4 className="clrGreen">
                    {assessmentApplicantDetails?.cutOff
                      ? assessmentApplicantDetails?.cutOff + "%"
                      : "-"}
                  </h4>
                </div>
                <div className="rightBorder"></div>
                <div className="assessOverallScoreSec">
                  <p className="d-flex gap-2 justify-content-center">
                    {t("RESPONSE_LIST.OVERALL_SCORE")}{" "}
                    {!assessmentApplicantDetails?.finalScore ? (
                      <InfoIcon />
                    ) : (
                      ""
                    )}
                  </p>
                  <h4 className="clrGreen">
                    {assessmentApplicantDetails?.finalScore
                      ? assessmentApplicantDetails?.finalScore + "%"
                      : "-"}
                  </h4>
                </div>
              </div>
              {assessmentApplicantDetails?.remark && (
                <div className="applicantRemark">
                  <h4 className="clrGreen">
                    {assessmentApplicantDetails?.remark &&
                      assessmentApplicantDetails?.remark}
                  </h4>
                </div>
              )}
            </div>
          </div>

          <div className={"statusTimeSec mt-3"}>
            <div className="assessmenTimes">
              {assessmentApplicantDetails?.submittedDate && (
                <span>
                  {t("CREATE_SUBMITTED_ON")} :{" "}
                  <span className="timeValue">
                    {convertDate(
                      assessmentApplicantDetails?.submittedDate,
                      false,
                      true,
                    )}
                  </span>
                </span>
              )}
            </div>
            {/* <div className="assessmenTimes">
              <span>
                {t("CREATE_ASSESSMENT.TIME")} :{" "}
                <span className="timeValue">
                  {assessmentApplicantDetails?.totalTime} {t("GENERAL.MIN(S)")}
                </span>
              </span>
              <br />
              <span>
                {t("CREATE_RESPONSE.TIME")} :{" "}
                <span className="timeValue">
                  {Math.round(assessmentApplicantDetails?.actualTimeTaken / 60)}{" "}
                  {t("GENERAL.MIN(S)")}
                </span>
              </span>
            </div> */}
            <div className="assessmenTimes">
              <div className="timeRow">
                <span className="label">{t("CREATE_ASSESSMENT.TIME")}</span>
                <span className="colon">:</span>
                <span className="timeValue">
                  {assessmentApplicantDetails?.totalTime} {t("GENERAL.MIN(S)")}
                </span>
              </div>
              <div className="timeRow">
                <span className="label">{t("CREATE_RESPONSE.TIME")}</span>
                <span className="colon">:</span>
                <span className="timeValue">
                  {Math.round(assessmentApplicantDetails?.actualTimeTaken / 60)}{" "}
                  {t("GENERAL.MIN(S)")}
                </span>
              </div>
            </div>
          </div>
          <div className="assessSelfRating">
            <div className="assessParaDiv">
              <p className="assessPara">
                {t("RESPONSE_LIST.APPLICANTS_SELF_RATING")} :
              </p>
            </div>
            <div className="assessSkills">
              {assessmentApplicantSkillExperience &&
                assessmentApplicantSkillExperience.length > 0 &&
                assessmentApplicantSkillExperience.map(
                  (curEl: any, index: number) => (
                    <div className="assessSkillDiv" key={index}>
                      <p>{curEl?.skill?.skillName} - </p>
                      <span>{curEl?.experience || t("GENERAL.NA")}</span>
                    </div>
                  ),
                )}
            </div>
          </div>
          <div className="reviewInputField">
            <p>{t("RESPONSE_LIST.FINAL_COMMENT")}</p>
            <InputField
              additionalProps={{
                as: "textarea",
                rows: 4,
                style: { resize: "none" },
              }}
              value={commentText}
              placeholder={t("GENERAL.COMMENT")}
              onChange={(e) => handleCommentText(null, e)}
            />
          </div>
          <div className="addEvaluationComment">
            <AssessmentButton
              className="whiteBtn"
              disabled={commentText?.length > 0 ? false : true}
              onClick={() => handleAddEvaluationComment()}
            >
              {t("GENERAL.SAVE")}
            </AssessmentButton>
          </div>
        </div>
        {/* Category wise score */}
        <div className="analysisScore mt-5 d-flex align-items-center justify-content-between">
          <div className="smartAi">
            <span>{t("GENERAL.CATEGORIES")}</span>
          </div>
        </div>
        <div className="categoriesSec">
          {categorieScore?.map((item: any, index: any) => {
            return (
              <div key={index}>
                <span className="categoryName">{item?.categoryName}</span>
                <div className="bar-sec">
                  <BarGraph score={item?.totalScore} />
                  <span className="bar-score">
                    {item?.totalScore ? item?.totalScore + "%" : "0%"}
                  </span>
                </div>
              </div>
            );
          })}
        </div>
        {/* Skills wise score */}
        <div className="analysisScore mt-5 d-flex align-items-center justify-content-between">
          <div className="smartAi">
            <span>{t("GENERAL.SKILLS_TITLE")}</span>
          </div>
          <div className="scroll-Controllers">
            <div
              onClick={() => {
                handleScroll("prev", skillsCardRef);
              }}
            >
              <PreviousArrow color={"#35d4ae"} />
            </div>
            <div
              onClick={() => {
                handleScroll("next", skillsCardRef);
              }}
            >
              <NextArrow color={"#35d4ae"} />
            </div>
          </div>
        </div>
        <div className="Skills-card" ref={skillsCardRef}>
          {skillScore?.map((item: any, index: any) => {
            return (
              <div key={index}>
                <SkillScoreCard
                  totalScoreTabQuestions={
                    assessmentApplicantDetails?.totalQuestions
                  }
                  totalQuestions={item?.totalQuestions}
                  skillTitle={item?.key}
                  questionType={item?.questionType}
                  scorePercentage={item?.score}
                  onScoreCardClick={() => {
                    onScoreCardClick(item);
                  }}
                  selected={
                    selectedButton === item?.key ||
                    (Array.isArray(selectedButton) &&
                      selectedButton.includes(item?.skillId))
                  }
                  categoryName={item?.categoryName}
                />
              </div>
            );
          })}
        </div>
        {/* {evaluationSummary === null && ( */}
        {selectedButton !== "Custom" &&
          (questionType.length !== 1 || !questionType?.includes("MCQ")) && (
            <>
              {/* AI Question Analysis */}
              <div className="analysisScore mt-5">
                <div className="smartAi">
                  <span>{t("RESPONSE_LIST.AI_ANALYSIS")}</span>
                </div>
              </div>
              <AiQuestionEvaluation
                t={t}
                evaluationSummary={evaluationSummary?.aiAnalysis}
                questionData={questionData}
                selectedTab={selectedButton}
                isAiAnalysis={evaluationTypesObj?.attributes?.aiAnalysis}
              />
              {/* Visual Analysis */}
              <div className="analysisScore mt-5">
                <div className="smartAi">
                  <span>{t("RESPONSE_LIST.VISUAL_ANALYSIS")}</span>
                </div>
              </div>
              <Voice_VisualAnalysis
                t={t}
                evaluationSummary={evaluationSummary?.visualAnalysis}
                isVisualAnalysis={
                  evaluationTypesObj?.attributes?.visualAnalysis
                }
              />
              {/* voice analysis */}
              <div className="analysisScore mt-5">
                <div className="smartAi">
                  <span>{t("RESPONSE_LIST.VOICE_ANALYSIS")}</span>
                </div>
              </div>
              <div className="additionalAnalysis mt-3">
                <div className="row">
                  <div className="col-lg-3">
                    <div className="analysisSub mb-4">
                      <p>{t("GENERAL.VOICE_CONFIDENCE")}</p>
                      <span>
                        {evaluationTypesObj?.attributes?.audioAnalysis
                          ? Math.round(evaluationSummary?.voiceAnalysis) + "%"
                          : // : t("GENERAL.NA")
                            "-"}
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </>
          )}
        {/* )} */}
        {/* Question list */}
        <div className="assessResponseHead mt-5">
          <div>
            <div className="assessResponseHeadContent">
              <h3>{t("GENERAL.QUESTIONS")}</h3>
            </div>
            <div>
              <p>
                {t("CREATE_ASSESSMENT.TIME")} :{" "}
                <span>
                  {assessmentApplicantDetails?.totalTime}
                  &nbsp;{t("GENERAL.MINS")}
                </span>
              </p>
            </div>
          </div>
          <div className="wrapperFilterQuestion">
            {/* <div className="filtersBtn">
              <span>{t("RESPONSE_LIST.FILTER")}</span>
              <div className="finalCommentButtons">
                {buttons.map((button) => (
                  <AssessmentButton
                    key={button.id}
                    onClick={() => handleButtonClick(button.id)}
                    className={
                      selectedButton === button.id ? "customBtn" : "customClass"
                    }
                  >
                    {t(button.label)}
                  </AssessmentButton>
                ))}
              </div>
            </div> */}
            <div className="totalQuestions">
              {`${t("CREATE_ASSESSMENT.TOTAL_QUESTION")} : ${totalAssessmentQuestions} / ${assessmentApplicantDetails?.totalQuestions}`}
            </div>
          </div>
        </div>
        <div className="responseReviewQuestion mt-3" ref={scrollRef}>
          {questionData?.length ? (
            (questionData ?? []).map((item: any, index: number) => {
              const confidence = filterAiConfidence(item?.evaluationResult);
              return (
                <>
                  <div
                    onClick={() => handleResponseListClick(item, index)}
                    className={`assessResponseList ${scrollAppend(item.questionId, activeQueID)}`}
                    key={index}
                  >
                    <div className="assessQuestion">
                      <div className="questionDetails">
                        <div className="typeStatus">
                          <div className="bulletSec">
                            <div className="questionSkillName">
                              {item?.isCustomQuestion
                                ? t("GENERAL.CUSTOM_QUESTION")
                                : `${item?.skillName} - ${item?.difficultyLevel} `}
                            </div>
                          </div>
                          <p className="d-flex">
                            {t("GENERAL.TYPE")} :
                            <span className="answerType">{item?.type}</span>
                          </p>
                          {item?.status === "skipped" && (
                            <>
                              <div className="borderRight"></div>
                              <p className="answerStatus">{item?.status}</p>
                            </>
                          )}
                          {item?.isReported && (
                            <>
                              <div className="borderRight"></div>
                              <p className="answerStatus">
                                {t("GENERAL.REPORTED")}
                              </p>
                            </>
                          )}
                        </div>
                      </div>
                      <div className="d-flex gap-2">
                        {/* <p>{index + 1}.</p> */}
                        <p>{item?.questionSequence}</p>
                        <p
                          dangerouslySetInnerHTML={{
                            __html: ` ${item?.question}`,
                          }}
                        />
                      </div>
                    </div>
                    <div className="assessScore">
                      <div className="d-flex justify-content-center gap-1">
                        {confidence && <p>{t("GENERAL.AI_Confidence")}</p>}
                      </div>
                      {confidence ? (
                        <h4 className="clrGreen">{confidence + "%"}</h4>
                      ) : (
                        "-"
                      )}
                    </div>
                    <div className="assessScore">
                      <div className="d-flex justify-content-center gap-1">
                        <p>{t("GENERAL.SCORE")}</p>
                        {item?.scoreType !== "auto" && (
                          <span>
                            {item?.scoreType === null
                              ? ""
                              : item?.scoreType === "smartAi"
                                ? `(${t("RESPONSE_LIST.AI")})`
                                : `(${t("GENERAL.MANUAL")})`}
                          </span>
                        )}
                      </div>
                      <h4 className="clrGreen">
                        {item?.totalScore === null ||
                        item?.totalScore === undefined
                          ? "-"
                          : `${item.totalScore}%`}
                      </h4>
                    </div>
                  </div>
                </>
              );
            })
          ) : (
            <DataNotAvailable />
          )}
        </div>
        <div className="reportedList">
          <ReportedQuestionsList
            applicantId={applicantId}
            assessmentId={assessmentIdParam}
          />
        </div>
      </div>
      {showQuestion && questionRapperData?.questionNo !== null && (
        <QuestionResponsePopup
          setShowQuestion={setShowQuestion}
          assessmentApplicantDetails={assessmentApplicantDetails}
          assessmentSkill={assessmentSkill}
          questionRapperData={questionRapperData}
          assessmentDetails={assessmentDetails}
          assessmentDateTime={convertDate(
            assessmentApplicantDetails?.updatedAt,
            false,
            true,
          )}
          questionData={questionData}
          setIsScoreUpdated={setIsScoreUpdated}
          evaluationTypesObj={evaluationTypesObj}
        />
      )}
      {isNotifyPopupVisible && (
        <NotifyConfirmPopup
          isPopupShow={isNotifyPopupVisible}
          setIsClosePopup={setIsNotifyPopupVisible}
          handleYesPress={notifyAnApplicantApi}
          isDisable={notifyBtnDisable}
        />
      )}
    </>
  );
}

export default CreatedAssessmentList;
