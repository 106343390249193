import { AssessmentButton, InputField } from "ui-components";
interface AddReport {
  value: string;
  placeHolder: string;
  btnName: string;
  setFn: any;
  handleSubmit: () => void;
  setIsScoreUpdated: any;
}
const AddReport: React.FC<AddReport> = ({
  value,
  placeHolder,
  btnName,
  setFn,
  handleSubmit,
  setIsScoreUpdated,
}) => {
  const handlechange = (id: null, value: any) => {
    setFn(value);
  };
  return (
    <div className="Reports ">
      <div className="reportInputField w-100">
        <InputField
          additionalProps={{
            as: "textarea",
            rows: 2,
            style: { resize: "none" },
          }}
          value={value}
          placeholder={placeHolder}
          onChange={(e) => {
            handlechange(null, e);
          }}
        />
      </div>
      <div className="reportBtn mt-3">
        <AssessmentButton
          onClick={() => {
            handleSubmit();
            setIsScoreUpdated(Math.random() * 10);
          }}
        >
          {btnName}
        </AssessmentButton>
      </div>
    </div>
  );
};

export default AddReport;
