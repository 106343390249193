import { useTranslation } from "react-i18next";
import "./ReviewSummary.scss";
import { getSessionStorageItem } from "../../../../../utils/SessionStorageMethod/getSessionStorageItem";
import { useEffect } from "react";

const ReviewSummary: React.FunctionComponent = () => {
  const { t } = useTranslation();
  // Run this effect whenever the session storage is updated
  const questionCount = getSessionStorageItem("totalQuestionCount");
  // Check if the items exist before accessing their properties
  const questionCounted = questionCount > 0 ? questionCount : 0;
  useEffect(() => {}, [questionCounted]);

  return (
    <>
      <div className="totalQuesSet">
        <div>
          <h5>{t("CREATE_ASSESSMENT.TOTAL_QUESTION")}</h5>
          <p className="boldText">
            {getSessionStorageItem("totalQuestionCount") ?? 0}
          </p>
        </div>
        <div>
          <h5>{t("CREATE_ASSESSMENT.EXPERIENCE_YEAR")}</h5>
          <p>
            <b className="minMax">{t("GENERAL.MIN")}</b> :&nbsp;
            <span className="boldText">
              {getSessionStorageItem("minimumExperience") || 0}
            </span>
          </p>
          <p>
            <b className="minMax">{t("GENERAL.MAX")}</b> :&nbsp;
            <span className="boldText">
              {getSessionStorageItem("maximumExperience") || 0}
            </span>
          </p>
        </div>
        <div>
          <h5>{t("CREATE_ASSESSMENT.POSITION")}</h5>
          <p className="boldText">
            {getSessionStorageItem("noOfPositions") || 0}
          </p>
        </div>
        <div>
          <h5>{t("CREATE_ASSESSMENT.CUTOFF_PERCENTAGE")}</h5>
          <p className="boldText">{getSessionStorageItem("cutOff") || 0}%</p>
        </div>
        <div>
          <h5>{t("CREATE_ASSESSMENT.MAXIMUM_RESPONSES")}</h5>
          <p className="boldText">
            {getSessionStorageItem("maximumResponse") || 0}
          </p>
        </div>
        <div>
          <h5>{t("CREATE_ASSESSMENT.TIME")}</h5>
          <p className="boldText">
            {getSessionStorageItem("totalQuestionTime") ?? 0}{" "}
            {t("GENERAL.MINS")}
          </p>
        </div>
      </div>
      <div className="horizontalLine"></div>
    </>
  );
};

export default ReviewSummary;
