import { AssessmentButton, InputField, showToast } from "ui-components";
import CompanyOnboardWrapper from "../../../components/CompanyOnboard/CompanyOnboardWrapper";
import "./LoginSignup.scss";
import { Field, Form, Formik } from "formik";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import * as Yup from "yup";
import { companyApplicant, createPassword } from "../../../service/Scope/Scope";
import { loginUserData } from "../../../utils/Common/Constant";
import { v4 as uuidv4 } from "uuid";
const CreatePasswordScreen = () => {
  const { t, i18n } = useTranslation();
  // Get current language
  const currentLanguage = i18n.language;
  const navigate = useNavigate();
  const email: any = localStorage.getItem("email");
  const companyUserId: any = localStorage.getItem("companyUserId");

  // login User Schema
  const loginUserSchema = Yup.object().shape({
    password: Yup.string()
      .min(6, t("ERROR.PASSWORD_MUST"))
      .max(32, t("ERROR.PASSWORD_MUST"))
      .matches(/[a-zA-Z]/, t("ERROR.PASSWORD_LETTER"))
      .matches(/[0-9]/, t("ERROR.PASSWORD_NUMBER"))
      .matches(
        /[!@#$%^&*(),.?":{}\\|<>`~\-_=+[\];:'"<>/?]/,
        t("ERROR.PASSWORD_SPECIAL"),
      )

      .trim()
      .required(t("ERROR.PASSWORD_REQUIRED")),
    confirmPassword: Yup.string()
      .oneOf(
        [Yup.ref("password"), undefined],
        t("ERROR.CONFIRM_PASSWORD_MATCH"),
      )
      .trim()
      .required(t("ERROR.CONFIRM_PASSWORD_REQUIRED")),
  });

  // login applicant
  const handlePasswordCreation = async (
    value: any,
    resetForm?: () => void,
    setSubmitting?: (data: boolean) => any,
  ) => {
    const data = {
      companyUserId: companyUserId,
      password: value?.password.trim(),
    };
    try {
      // Set isSubmitting to true to indicate that form submission is in progress
      setSubmitting && setSubmitting(true);
      const createCompanyUserPassword = await createPassword(data);
      if (createCompanyUserPassword?.status === 200) {
        resetForm && resetForm();
        showToast(createCompanyUserPassword?.customMessage, "success");
        handleApplicantLogin(value?.password.trim());
      }
    } catch (error) {
      console.error("Error submitting form:", error);
    } finally {
      // Ensure isSubmitting is set to false, even if an error occurs during submission
      setSubmitting && setSubmitting(false);
    }
  };

  const handleApplicantLogin = async (password: any) => {
    const data = {
      emailId: email,
      password: password,
    };
    try {
      const sendInvitationRes = await companyApplicant(data);
      if (sendInvitationRes?.status === 200) {
        const uniqueId = uuidv4();
        localStorage.setItem(
          "companyToken",
          sendInvitationRes?.data?.accessToken,
        );
        localStorage.setItem("companyId", sendInvitationRes?.data?.companyId);
        localStorage.setItem(
          "companyUserId",
          sendInvitationRes?.data?.companyUserId,
        );
        localStorage.setItem("mediaPath", sendInvitationRes?.data?.mediaPath);
        localStorage.setItem("industryId", sendInvitationRes?.data?.industryId);
        localStorage.setItem("tempUniqueId", uniqueId);
        localStorage.setItem(
          "companyAddress",
          sendInvitationRes?.data?.address,
        );
        navigate(`/${currentLanguage}/assessment/list`);
      }
    } catch (error) {
      console.error("Error submitting form:", error);
    }
  };
  const onSignInClick = () => {
    navigate("/");
  };
  return (
    <CompanyOnboardWrapper
      lowerSubmitText1={t("GENERAL.ALREADY_ACCOUNT")}
      lowerSubmitText2={t("GENERAL.SIGN_IN")}
      signInUpClick={onSignInClick}
    >
      <Formik
        initialValues={loginUserData}
        validationSchema={loginUserSchema}
        onSubmit={(value, { resetForm, setSubmitting }) => {
          handlePasswordCreation(value, resetForm, setSubmitting);
        }}
      >
        {({ errors, touched, values, setFieldValue, isSubmitting }) => {
          return (
            <div className="companyLogin">
              <Form>
                <div className="userSec">
                  <div className="userContent">
                    <div className="heading-div">
                      <h4 className="userHeadingApp">
                        {t("GENERAL.REGISTER")}
                      </h4>
                      <p className="userHeading">
                        {t("GENERAL.CREATE_YOUR_PASSWORD")}
                      </p>
                    </div>
                    <Field name="email">
                      {() => (
                        <InputField
                          value={email}
                          additionalProps={{
                            className: "textInput disable-email",
                            disabled: true,
                          }}
                        />
                      )}
                    </Field>
                    <Field name="password">
                      {() => (
                        <div className="passwordSec">
                          <InputField
                            placeholder={`${t("GENERAL.NEW_PASSWORD")} *`}
                            onChange={(newValue) =>
                              setFieldValue("password", newValue)
                            }
                            value={values.password}
                            additionalProps={{
                              className: "newPasswordInput",
                            }}
                            type="password"
                          />
                        </div>
                      )}
                    </Field>
                    {errors?.password && touched?.password ? (
                      <div className="errorMessage">{errors?.password}</div>
                    ) : null}
                    <Field name="confirmPassword">
                      {() => (
                        <div className="passwordSec">
                          <InputField
                            placeholder={`${t("GENERAL.CONFIRM_PASSWORD")} *`}
                            onChange={(newValue) =>
                              setFieldValue("confirmPassword", newValue)
                            }
                            value={values.confirmPassword}
                            additionalProps={{
                              className:
                                "newPasswordInput confirmPasswordPlaceholder",
                            }}
                            type="password"
                          />
                        </div>
                      )}
                    </Field>
                    {errors?.confirmPassword && touched?.confirmPassword ? (
                      <div className="errorMessage">
                        {errors?.confirmPassword}
                      </div>
                    ) : null}

                    <AssessmentButton
                      className="submitBtn submitBtnCreatePassword"
                      type="submit"
                      disabled={isSubmitting}
                    >
                      {isSubmitting
                        ? t("GENERAL.LOADING")
                        : t("GENERAL.SIGN_IN")}
                    </AssessmentButton>
                  </div>
                </div>
              </Form>
              <div className="password-instructions">
                <ul>
                  <li>{t("GENERAL.INSTRUCTION1")}</li>
                  <li>{t("GENERAL.INSTRUCTION2")}</li>
                  <li>{t("GENERAL.INSTRUCTION3")}</li>
                </ul>
              </div>
            </div>
          );
        }}
      </Formik>
    </CompanyOnboardWrapper>
  );
};

export default CreatePasswordScreen;
