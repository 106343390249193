import { useTranslation } from "react-i18next";
import FilterButton from "../../../components/FilterButton/FilterButton";
interface Filter {
  filters: any;
  handleFilter: (value: any) => void;
  filterCountValue: any;
}
const Filter: React.FC<Filter> = ({
  filters,
  handleFilter,
  filterCountValue,
}) => {
  const { t } = useTranslation();
  return (
    <div className="scoreFilter">
      <FilterButton
        handleFilter={handleFilter}
        name="all"
        isActive={filters?.all}
      >
        {t("GENERAL.ALL")}({filterCountValue ? filterCountValue?.all : null})
      </FilterButton>
      <FilterButton
        handleFilter={handleFilter}
        name="notInitiated"
        isActive={filters?.notInitiated}
      >
        {t("GENERAL.NOT.INITIATED")}(
        {filterCountValue ? filterCountValue?.notInititated : null})
      </FilterButton>

      <FilterButton
        handleFilter={handleFilter}
        name="initiated"
        isActive={filters?.initiated}
      >
        {t("GENERAL.INITIATED")}(
        {filterCountValue ? filterCountValue?.initiated : null})
      </FilterButton>
      <FilterButton
        handleFilter={handleFilter}
        name="submitted"
        isActive={filters?.submitted}
      >
        {t("GENERAL.SUBMITTED")}(
        {filterCountValue ? filterCountValue?.submitted : null})
      </FilterButton>
      <FilterButton
        handleFilter={handleFilter}
        name="deffered"
        isActive={filters?.deffered}
      >
        {t("GENERAL.DEFFERED")}(
        {filterCountValue ? filterCountValue?.deferred : null})
      </FilterButton>
      <FilterButton
        handleFilter={handleFilter}
        name="reviewed"
        isActive={filters?.reviewed}
      >
        {t("GENERAL.REVIEWED")}(
        {filterCountValue ? filterCountValue?.reviewed : null})
      </FilterButton>
      <FilterButton
        handleFilter={handleFilter}
        name="selected"
        isActive={filters?.selected}
      >
        {t("GENERAL.SELECTED")}(
        {filterCountValue ? filterCountValue?.selected : null})
      </FilterButton>
      <FilterButton
        handleFilter={handleFilter}
        name="notified"
        isActive={filters?.notified}
      >
        {t("GENERAL.NOTIFY")}(
        {filterCountValue ? filterCountValue?.notified : null})
      </FilterButton>
      <FilterButton
        handleFilter={handleFilter}
        name="notNotified"
        isActive={filters?.notNotified}
      >
        {t("GENERAL.NOT_NOTIFY")}(
        {filterCountValue ? filterCountValue?.notNotified : null})
      </FilterButton>
    </div>
  );
};

export default Filter;
