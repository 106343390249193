import { useTranslation } from "react-i18next";
import { AssessmentCheckboxRadio } from "ui-components";
import "./AdvanceSetting.scss";
interface EngagementType {
  setAdvanceSettingData: any;
  advanceSettingData: any;
}
const EngagementType: React.FC<EngagementType> = ({
  setAdvanceSettingData,
  advanceSettingData,
}) => {
  const { t } = useTranslation();
  return (
    <div className="engagementType">
      <span>{t("CREATE_ASSESSMENT.ENGAGEMENT")}</span>
      <div className="engagementTypeRow mt-3">
        <div
          className="d-flex engagementTypeCursorPointer rtlGap"
          style={{
            pointerEvents:
              advanceSettingData && advanceSettingData?.disable
                ? "none"
                : "auto",
          }}
          onClick={() => {
            setAdvanceSettingData((prev: any) => {
              const updatedData = { ...prev, engagementType: "onSite" };
              sessionStorage.setItem(
                "advanceSettingData",
                JSON.stringify(updatedData),
              );
              return updatedData;
            });
          }}
        >
          <AssessmentCheckboxRadio
            name="onSite"
            type="radio"
            checked={advanceSettingData?.engagementType == "onSite"}
            // additionalProps={{ disabled: advanceSettingData?.disable }}
          />
          <p className="radioCheckLabel">{t("CREATE_ASSESSMENT.ONSITE")}</p>
        </div>
        <div
          className="d-flex engagementTypeCursorPointer rtlGap"
          style={{
            pointerEvents:
              advanceSettingData && advanceSettingData?.disable
                ? "none"
                : "auto",
          }}
          onClick={() => {
            setAdvanceSettingData((prev: any) => {
              const updatedData = { ...prev, engagementType: "hybrid" };
              sessionStorage.setItem(
                "advanceSettingData",
                JSON.stringify(updatedData),
              );
              return updatedData;
            });
          }}
        >
          <AssessmentCheckboxRadio
            name="hybrid"
            type="radio"
            checked={advanceSettingData?.engagementType == "hybrid"}
          />
          <p className="radioCheckLabel">{t("CREATE_ASSESSMENT.HYBRID")}</p>
        </div>
        <div
          className="d-flex engagementTypeCursorPointer rtlGap"
          style={{
            pointerEvents:
              advanceSettingData && advanceSettingData?.disable
                ? "none"
                : "auto",
          }}
          onClick={() => {
            setAdvanceSettingData((prev: any) => {
              const updatedData = { ...prev, engagementType: "remote" };
              sessionStorage.setItem(
                "advanceSettingData",
                JSON.stringify(updatedData),
              );
              return updatedData;
            });
          }}
        >
          <AssessmentCheckboxRadio
            name="remote"
            type="radio"
            checked={advanceSettingData?.engagementType == "remote"}
          />
          <p className="radioCheckLabel">{t("CREATE_ASSESSMENT.REMOTE")}</p>
        </div>
      </div>
    </div>
  );
};

export default EngagementType;
