const HeaderLogo = () => {
  return (
    <svg
      width="143"
      height="33"
      viewBox="0 0 143 33"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M52.4571 23.9578C50.9157 23.9578 49.5861 23.6129 48.4682 22.9232C47.3573 22.2265 46.4998 21.2553 45.8957 20.0096C45.2986 18.7569 45 17.3001 45 15.6392C45 13.9782 45.2986 12.5144 45.8957 11.2476C46.4998 9.97377 47.3399 8.98145 48.4161 8.27063C49.4993 7.55278 50.763 7.19386 52.2072 7.19386C53.0404 7.19386 53.8632 7.33461 54.6755 7.61612C55.4879 7.89763 56.2274 8.35509 56.8939 8.98848C57.5605 9.61484 58.0917 10.4453 58.4874 11.4798C58.8832 12.5144 59.0811 13.7882 59.0811 15.3013V16.357H46.7497V14.2035H56.5815C56.5815 13.2885 56.401 12.4722 56.0399 11.7543C55.6858 11.0365 55.1789 10.4699 54.5193 10.0547C53.8666 9.63948 53.0959 9.43186 52.2072 9.43186C51.2282 9.43186 50.3811 9.67818 49.6659 10.1708C48.9577 10.6564 48.4127 11.2898 48.0308 12.071C47.6489 12.8522 47.4579 13.6897 47.4579 14.5835V16.0192C47.4579 17.2438 47.6662 18.2818 48.0828 19.1334C48.5064 19.9779 49.0931 20.6219 49.843 21.0653C50.5929 21.5016 51.4642 21.7198 52.4571 21.7198C53.1029 21.7198 53.6861 21.6283 54.2069 21.4453C54.7346 21.2553 55.1893 20.9738 55.5712 20.6008C55.9531 20.2207 56.2482 19.7492 56.4565 19.1862L58.8311 19.8618C58.5812 20.6782 58.1611 21.396 57.5709 22.0154C56.9807 22.6276 56.2517 23.1062 55.3838 23.4511C54.5158 23.7889 53.5403 23.9578 52.4571 23.9578Z"
        fill="black"
      />
      <path
        d="M75.4144 7.40499L69.4987 23.62H66.9991L61.0834 7.40499H63.7496L68.1656 20.3263H68.3322L72.7482 7.40499H75.4144Z"
        fill="black"
      />
      <path d="M81.1166 2V23.62H78.6587V2H81.1166Z" fill="black" />
      <path
        d="M95.7002 16.9904V7.40499H98.1582V23.62H95.7002V20.8752H95.5336C95.1587 21.6987 94.5754 22.3989 93.7839 22.976C92.9923 23.5461 91.9925 23.8311 90.7844 23.8311C89.7845 23.8311 88.8958 23.6094 88.1181 23.166C87.3405 22.7156 86.7295 22.04 86.2851 21.1392C85.8407 20.2313 85.6185 19.0877 85.6185 17.7083V7.40499H88.0765V17.5393C88.0765 18.7217 88.4028 19.6647 89.0555 20.3685C89.7151 21.0723 90.5552 21.4242 91.5759 21.4242C92.1869 21.4242 92.8084 21.2658 93.4402 20.9491C94.079 20.6324 94.6136 20.1468 95.0441 19.4923C95.4815 18.8378 95.7002 18.0038 95.7002 16.9904Z"
        fill="black"
      />
      <path
        d="M107.368 24C106.354 24 105.434 23.8065 104.608 23.4194C103.781 23.0253 103.125 22.4587 102.639 21.7198C102.153 20.9738 101.91 20.0729 101.91 19.0173C101.91 18.0883 102.091 17.3353 102.452 16.7582C102.813 16.174 103.295 15.7166 103.899 15.3858C104.504 15.055 105.17 14.8087 105.899 14.6468C106.635 14.4779 107.375 14.3442 108.118 14.2457C109.09 14.119 109.878 14.024 110.482 13.9607C111.093 13.8903 111.537 13.7742 111.815 13.6123C112.1 13.4504 112.242 13.1689 112.242 12.7678V12.6833C112.242 11.6417 111.961 10.8324 111.398 10.2553C110.843 9.67818 109.999 9.38964 108.867 9.38964C107.694 9.38964 106.774 9.65003 106.107 10.1708C105.441 10.6916 104.972 11.2476 104.701 11.8388L102.368 10.9942C102.785 10.009 103.341 9.24184 104.035 8.6929C104.736 8.13692 105.5 7.74984 106.326 7.53167C107.159 7.30646 107.979 7.19386 108.784 7.19386C109.298 7.19386 109.888 7.2572 110.555 7.38388C111.228 7.50352 111.877 7.75336 112.502 8.1334C113.134 8.51344 113.658 9.08701 114.075 9.85413C114.492 10.6212 114.7 11.6488 114.7 12.9367V23.62H112.242V21.4242H112.117C111.95 21.7761 111.673 22.1526 111.284 22.5537C110.895 22.9549 110.378 23.2962 109.732 23.5777C109.086 23.8592 108.298 24 107.368 24ZM107.743 21.762C108.715 21.762 109.534 21.5685 110.201 21.1814C110.874 20.7943 111.381 20.2946 111.721 19.6823C112.068 19.0701 112.242 18.4261 112.242 17.7505V15.4703C112.138 15.5969 111.909 15.7131 111.554 15.8186C111.207 15.9171 110.805 16.0051 110.346 16.0825C109.895 16.1529 109.454 16.2163 109.024 16.2726C108.6 16.3218 108.256 16.364 107.993 16.3992C107.354 16.4837 106.757 16.6209 106.201 16.8109C105.653 16.9939 105.208 17.2719 104.868 17.6449C104.535 18.0109 104.368 18.5106 104.368 19.144C104.368 20.0096 104.684 20.6641 105.316 21.1075C105.955 21.5438 106.764 21.762 107.743 21.762Z"
        fill="black"
      />
      <path
        d="M126.143 7.40499V9.51631H117.853V7.40499H126.143ZM120.269 3.52015H122.727V18.975C122.727 19.6788 122.828 20.2067 123.029 20.5585C123.238 20.9034 123.501 21.1356 123.821 21.2553C124.147 21.3679 124.491 21.4242 124.852 21.4242C125.123 21.4242 125.345 21.4101 125.518 21.382C125.692 21.3468 125.831 21.3186 125.935 21.2975L126.435 23.5355C126.268 23.5989 126.036 23.6622 125.737 23.7255C125.439 23.7959 125.06 23.8311 124.602 23.8311C123.908 23.8311 123.227 23.6798 122.561 23.3772C121.901 23.0745 121.352 22.6136 120.915 21.9942C120.484 21.3749 120.269 20.5937 120.269 19.6507V3.52015Z"
        fill="black"
      />
      <path
        d="M136.376 23.9578C134.835 23.9578 133.505 23.6129 132.387 22.9232C131.276 22.2265 130.419 21.2553 129.815 20.0096C129.217 18.7569 128.919 17.3001 128.919 15.6392C128.919 13.9782 129.217 12.5144 129.815 11.2476C130.419 9.97377 131.259 8.98145 132.335 8.27063C133.418 7.55278 134.682 7.19386 136.126 7.19386C136.959 7.19386 137.782 7.33461 138.594 7.61612C139.407 7.89763 140.146 8.35509 140.813 8.98848C141.479 9.61484 142.011 10.4453 142.406 11.4798C142.802 12.5144 143 13.7882 143 15.3013V16.357H130.669V14.2035H140.5C140.5 13.2885 140.32 12.4722 139.959 11.7543C139.605 11.0365 139.098 10.4699 138.438 10.0547C137.786 9.63948 137.015 9.43186 136.126 9.43186C135.147 9.43186 134.3 9.67818 133.585 10.1708C132.877 10.6564 132.332 11.2898 131.95 12.071C131.568 12.8522 131.377 13.6897 131.377 14.5835V16.0192C131.377 17.2438 131.585 18.2818 132.002 19.1334C132.425 19.9779 133.012 20.6219 133.762 21.0653C134.512 21.5016 135.383 21.7198 136.376 21.7198C137.022 21.7198 137.605 21.6283 138.126 21.4453C138.653 21.2553 139.108 20.9738 139.49 20.6008C139.872 20.2207 140.167 19.7492 140.375 19.1862L142.75 19.8618C142.5 20.6782 142.08 21.396 141.49 22.0154C140.9 22.6276 140.171 23.1062 139.303 23.4511C138.435 23.7889 137.459 23.9578 136.376 23.9578Z"
        fill="black"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M32.4232 16.231C32.4472 16.6824 31.8767 17.1349 30.6898 17.5232C24.0132 19.7109 19.536 24.0558 17.3814 30.7583C16.9679 31.9122 16.5947 32.4673 16.212 32.4619C15.8289 32.4673 15.456 31.9122 15.0425 30.7583C12.8879 24.0558 8.41079 19.7108 1.7342 17.5232C0.547255 17.1349 -0.0232205 16.6824 0.000723192 16.231C-0.0232208 15.7793 0.547255 15.3267 1.7342 14.9384C8.41078 12.7508 12.8879 8.40616 15.0425 1.70331C15.456 0.549814 15.8289 -0.00527732 16.212 3.78609e-05C16.5947 -0.00528128 16.9679 0.54981 17.3814 1.70331C19.536 8.40618 24.0132 12.7508 30.6898 14.9384C31.8767 15.3267 32.4472 15.7793 32.4232 16.231Z"
        fill="#00CCCC"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M22.5592 5.28666C22.5592 4.57606 23.1355 4 23.8463 4H42.111C42.8219 4 43.3981 4.57606 43.3981 5.28666C43.3981 5.99726 42.8219 6.57332 42.111 6.57332H23.8463C23.1355 6.57332 22.5592 5.99726 22.5592 5.28666ZM22.5592 27.0329C22.5592 26.3223 23.1355 25.7462 23.8463 25.7462H42.111C42.8219 25.7462 43.3981 26.3223 43.3981 27.0329C43.3981 27.7435 42.8219 28.3195 42.111 28.3195H23.8463C23.1355 28.3195 22.5592 27.7435 22.5592 27.0329Z"
        fill="#00CCCC"
      />
    </svg>
  );
};

export default HeaderLogo;
