import { useTranslation } from "react-i18next";
import HeaderLogo from "../../../../utils/Images/HeaderLogo";
import "./CreateProfile.scss";
import "react-phone-input-2/lib/bootstrap.css";
import {
  AssessmentButton,
  // AttachmentIcon,
  InputField,
  SearchTypeSelect,
  // TypeSelect,
  showToast,
} from "ui-components";
import ProfilePlaceholderIcon from "../../../../utils/Images/ProfilePlaceholderIcon";
import LinkedinIcon from "../../../../utils/Images/LinkedinIcon";
import TwitterIcon from "../../../../utils/Images/TwitterIcon";
import FacebookIcon from "../../../../utils/Images/FacebookIcon";
import InstagramIcon from "../../../../utils/Images/InstagramIcon";
import { Formik, Form, Field } from "formik";
import {
  completeProfileApi,
  getCompanyProfileDetails,
  getIndustries,
} from "../../../../service/Scope/Scope";
import { completeProfileData } from "../../../../utils/Common/Constant";
import * as Yup from "yup";
import { useEffect, useRef, useState } from "react";
import ImageCropPopup from "../../../../components/CropModal/CropModal";
import LocationSearch from "../../../../components/LocationSearch/LocationSearch";
import PhoneInput from "react-phone-input-2";
import { useLocation, useNavigate } from "react-router-dom";
import i18n from "../../../../i18nConfig";
import { ImageUploader } from "../../../../utils/Common/MediaUploader";
import { videoUploader } from "../../../../utils/Common/VideoUploader";
import useDebounce from "../../../../utils/Hooks/useDebounce";
import ChooseImageIcon from "../../../../utils/Images/ChooseImageIcon";
import BackArrow from "../../../../utils/Images/BackArrow";
const CreateProfileScreen = () => {
  const { t } = useTranslation();
  const [editDetails, setEditDetails] = useState<any>(null);
  const [industryList, setIndustryList] = useState<any>();
  const companyEmail: any = localStorage.getItem("email");
  const fileInputRef = useRef<HTMLInputElement | null>(null);
  const [fileInfo, setFileInfo] = useState<File | null>(null);
  const [isCropping, setIsCropping] = useState<boolean>(false);
  const [croppedImageUrl, setCroppedImageUrl] = useState<string | null>(null);
  const [lat, setLat] = useState("");
  const [lon, setLon] = useState("");
  const [city, setCity] = useState("");
  const [state, setState] = useState("");
  const [country, setCountry] = useState("");
  const [, setUploadedImg] = useState<string>("");
  const [croppedUploadedImg, setCroppedUploadedImg] = useState<string>(
    "" || editDetails?.logo,
  );
  const navigate = useNavigate();
  const location = useLocation();
  const { editProfile } = location.state;
  const mediaPath = localStorage.getItem("mediaPath");
  const currentLanguage = i18n.language;
  const companyUserId = localStorage.getItem("companyUserId");
  const getProfileDetails = async () => {
    const res = await getCompanyProfileDetails(companyUserId);
    setEditDetails(res?.data?.data);
  };
  useEffect(() => {
    if (editProfile) {
      getProfileDetails();
    }
  }, []);
  const handleApplicantSignup = async (
    value: any,
    resetForm?: () => void,
    setSubmitting?: (data: boolean) => any,
  ) => {
    localStorage.setItem("companyAddress", value?.address);
    const data = {
      industries: value?.industryType,
      companyUserId: localStorage.getItem("companyUserId"),
      companyId: localStorage.getItem("companyId"),
      companyName: value?.companyName.trim(),
      displayName: value?.displayName.trim(),
      website: value?.websiteUrl,
      companyLogo: croppedUploadedImg,
      companyEmail: companyEmail,
      companyMobile: value?.mobile,
      zipCode: Number(value?.zipcode),
      country: country || value?.country,
      state: state || value?.state,
      city: city || value?.city,
      instagramURL: value?.instagram,
      linkedInURL: value?.linkedin,
      facebookURL: value?.facebook,
      twitterURL: value?.twitter,
      lat: value?.latitude || lat,
      long: value?.longitude || lon,
      address: value?.address,
      contactPersonDetails: {
        firstName: value?.ownerFirstName.trim(),
        lastName: value?.ownerLastName.trim(),
        mobile: value?.ownerMobile,
        email: value?.ownerEmail?.toLowerCase(),
        designation: value?.ownerDesignation.trim(),
      },
      isProfileExist: editProfile,
    };
    try {
      setSubmitting && setSubmitting(true);
      const getCompanyOtp = await completeProfileApi(data);
      if (getCompanyOtp?.status === 200) {
        showToast(getCompanyOtp?.customMessage, "success");
        resetForm && resetForm();
        localStorage.setItem("industryId", value?.industryType);
        navigate(`/${currentLanguage}/assessment/list`);
      }
    } catch (error) {
      console.error("Error submitting form:", error);
    } finally {
      // Ensure isSubmitting is set to false, even if an error occurs during submission
      setSubmitting && setSubmitting(false);
    }
  };
  const getIndustryType = async (search: string) => {
    try {
      const industries: any = await getIndustries(search);
      if (industries?.status === 200) {
        const updateData = industries?.data
          ?.filter((item: any) => item?.isActive)
          ?.map((item: any) => ({
            value: item?.industriesId,
            label: item?.name,
          }));

        setIndustryList(updateData);
      }
    } catch (error) {
      console.error("Error in getting industru type");
    }
  };
  const signupUserSchema = Yup.object().shape({
    companyName: Yup.string().required(t("ERROR.COMPANY_NAME_REQUIRED")),
    industryType: Yup.string().required(t("ERROR.INDUSTRY_TYPE_REQUIRED")),
    displayName: Yup.string().required(t("ERROR.DISPLAY_NAME_REQUIRED")),
    websiteUrl: Yup.string()
      .url(t("ERROR.INVALID_URL"))
      .required(t("ERROR.WEBSITE_URL_REQUIRED")),
    mobile: Yup.string()
      .matches(/^[0-9]{5,20}$/, t("ERROR.INVALID_MOBILE_NUMBER"))
      .required(t("ERROR.MOBILE_REQUIRED")),
    address: Yup.string().required(t("ERROR.ADDRESS_REQUIRED")),
    city: Yup.string().required(t("ERROR.CITY_REQUIRED")),
    country: Yup.string().required(t("ERROR.COUNTRY_REQUIRED")),
    state: Yup.string().required(t("ERROR.STATE_REQUIRED")),
    zipcode: Yup.string().required(t("ERROR.ZIPCODE_REQUIRED")),
    linkedin: Yup.string().url(t("ERROR.INVALID_URL")),
    twitter: Yup.string().url(t("ERROR.INVALID_URL")),
    facebook: Yup.string().url(t("ERROR.INVALID_URL")),
    instagram: Yup.string().url(t("ERROR.INVALID_URL")),
    ownerFirstName: Yup.string().required(t("ERROR.FNAME_REQUIRED")),
    ownerLastName: Yup.string().required(t("ERROR.LNAME_REQUIRED")),
    ownerDesignation: Yup.string().required(t("ERROR.DESIGNATION_REQUIRED")),
    ownerEmail: Yup.string()
      .matches(
        /^(?!.*\.{2})[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9]+(?:\.[a-zA-Z0-9-]+)*$/,
        t("ERROR.INVALID_EMAIL_ADDRESS"),
      )
      .required(t("ERROR.EMAIL_REQUIRED")),
    ownerMobile: Yup.string().matches(
      /^[0-9]{5,20}$/,
      t("ERROR.INVALID_MOBILE_NUMBER"),
    ),
  });
  const handleSelectCompanyLogo = () => {
    if (fileInputRef.current) {
      fileInputRef.current.click();
    }
  };
  const onFileSelect = async (e: React.ChangeEvent<HTMLInputElement>) => {
    const file = e && e.target && e.target.files && e.target.files[0];
    if (file) {
      setFileInfo(file);
      setIsCropping(true);
      const ImageData = await ImageUploader(e, `${mediaPath + file?.name}`);
      setUploadedImg(ImageData);
    }
  };
  const handleCropComplete = async (croppedFile: Blob) => {
    const croppedUrl = URL.createObjectURL(croppedFile);
    const imageData = await videoUploader(
      croppedFile,
      `${mediaPath}croppedImage${Math.random() * 10}${fileInfo?.name}`,
    );
    setCroppedUploadedImg(imageData);
    setCroppedImageUrl(croppedUrl);
    setIsCropping(false);
  };

  return (
    <div className="container-fluid main-container">
      <div className="header-logo">
        <HeaderLogo />
      </div>
      <div className="backArrow" onClick={() => window.history.back()}>
        <BackArrow />
        <span>{t("GENERAL.COMPANY_PROFILE")}</span>
      </div>
      <Formik
        initialValues={completeProfileData}
        validationSchema={signupUserSchema}
        onSubmit={(value, { resetForm, setSubmitting }) => {
          handleApplicantSignup(value, resetForm, setSubmitting);
        }}
      >
        {({ errors, touched, values, setFieldValue, isSubmitting }) => {
          //setting data when it comes to edit
          const setProfileData = () => {
            setFieldValue("companyName", editDetails?.companyName);
            setFieldValue("displayName", editDetails?.displayName);
            setFieldValue("industryType", editDetails?.industries);
            setSkillIdFromSelect({
              value: editDetails?.industries,
              label: editDetails["industry.name"],
            });
            setFieldValue("websiteUrl", editDetails?.website);
            setFieldValue("mobile", editDetails?.mobile);
            setFieldValue("address", editDetails?.address);
            setFieldValue("country", editDetails?.country);
            setFieldValue("state", editDetails?.state);
            setFieldValue("city", editDetails?.city);
            setFieldValue("zipcode", editDetails?.zipCode);
            setFieldValue("linkedin", editDetails?.linkedIn);
            setFieldValue("twitter", editDetails?.twitter);
            setFieldValue("facebook", editDetails?.facebook);
            setFieldValue("instagram", editDetails?.instagram);
            setFieldValue(
              "ownerFirstName",
              editDetails?.contactPersonDetails?.firstName,
            );
            setFieldValue(
              "ownerLastName",
              editDetails?.contactPersonDetails?.lastName,
            );
            setFieldValue(
              "ownerDesignation",
              editDetails?.contactPersonDetails?.designation,
            );
            setFieldValue(
              "ownerEmail",
              editDetails?.contactPersonDetails?.email,
            );
            setFieldValue(
              "ownerMobile",
              editDetails?.contactPersonDetails?.mobile,
            );
            setCroppedUploadedImg(editDetails?.logo);
          };
          useEffect(() => {
            editDetails !== null && setProfileData();
          }, [editDetails]);
          useEffect(() => {
            country && setFieldValue("country", country);
            state && setFieldValue("state", state);
            city && setFieldValue("city", city);
          }, [city, state, country]);
          const defaultSelectSkills = { value: "", label: "" };
          const [skillIdFromSelect, setSkillIdFromSelect] =
            useState<any>(defaultSelectSkills);
          const debounceSkillSearchValue = useDebounce(
            skillIdFromSelect?.label?.trim(),
            400,
          );
          useEffect(() => {
            getIndustryType(skillIdFromSelect?.label);
          }, [debounceSkillSearchValue]);
          return (
            <Form>
              <div className="createProfile-container ">
                {/* <div className="createProfile-heading">
                  <span>{t("GENERAL.COMPLETE_PROFILE")}</span>
                </div> */}
                <div className="company-logo-name">
                  <div className="company-profile-image">
                    <div
                      className="image-circle"
                      onClick={handleSelectCompanyLogo}
                    >
                      {croppedUploadedImg || croppedImageUrl ? (
                        <div>
                          {/* <img src={croppedImageUrl} width={"100%"} /> */}
                          <img src={croppedUploadedImg} />
                        </div>
                      ) : (
                        <ProfilePlaceholderIcon />
                      )}
                      <input
                        type="file"
                        ref={fileInputRef}
                        style={{ display: "none" }}
                        onChange={(e: any) => onFileSelect(e)}
                        accept="image/*" // Optional: restrict to image files
                      />
                      <div className="ChooseImageIcon">
                        <ChooseImageIcon />
                      </div>
                    </div>
                    <div className="select-attachment">
                      {/* <span>{t("GENERAL.COMPANY_LOGO")}</span>
                      <span className="redStar">*</span>
                      <div
                        className="attachment-btn"
                        onClick={handleSelectCompanyLogo}
                      >
                        <span>{t("GENERAL.ATTACHMENT")}</span>
                        <input
                          type="file"
                          accept="image/*"
                          ref={fileInputRef}
                          onChange={(e: any) => onFileSelect(e)}
                          style={{ display: "none" }}
                        />
                        <AttachmentIcon />
                      </div> */}
                      {isCropping && fileInfo && (
                        <ImageCropPopup
                          show={isCropping}
                          file={fileInfo}
                          onClose={() => setIsCropping(false)}
                          onCropComplete={handleCropComplete}
                        />
                      )}
                    </div>
                  </div>
                  <div className="company-name-input">
                    <Field name="companyName">
                      {() => (
                        <InputField
                          placeholder={`${t("GENERAL.COMPANY_NAME")} *`}
                          onChange={(newValue: any) =>
                            setFieldValue("companyName", newValue)
                          }
                          value={values.companyName}
                          additionalProps={{
                            className: "textInput fullWidthInput",
                          }}
                        />
                      )}
                    </Field>
                    {errors?.companyName && touched?.companyName ? (
                      <div className="errorMessage">{errors?.companyName}</div>
                    ) : null}
                  </div>
                </div>
                <div className="details-container">
                  <div className="details">
                    <span>{t("GENERAL.COMPANY_DETAILS")}</span>
                  </div>
                  {/* row 1 */}
                  <div className="row inputsRow">
                    <div className="col-md-4">
                      <div className="detailsDropdown">
                        <Field name="industryType">
                          {() => (
                            <>
                              <SearchTypeSelect
                                options={
                                  industryList?.length ? industryList : []
                                }
                                placeholder={`${t("GENERAL.INDUSTRY_TYPE")} *`}
                                value={skillIdFromSelect}
                                onChange={(getValue: any) => {
                                  setSkillIdFromSelect(getValue);
                                  setFieldValue(
                                    "industryType",
                                    getValue?.value,
                                  );
                                }}
                                loadingText={t("GENERAL.LOADING")}
                                className="typeSelectDropdown industryTypePlaceholder"
                              />
                            </>
                          )}
                        </Field>
                        {errors?.industryType && touched?.industryType ? (
                          <div className="errorMessage">
                            {errors?.industryType}
                          </div>
                        ) : null}
                      </div>
                    </div>
                    <div className="col-md-4">
                      <Field name="displayName">
                        {() => (
                          <InputField
                            placeholder={`${t("GENERAL.DISPLAY_NAME")} *`}
                            onChange={(newValue: any) =>
                              setFieldValue("displayName", newValue)
                            }
                            value={values.displayName}
                            additionalProps={{
                              className: "textInput displayNamePlaceholder",
                            }}
                          />
                        )}
                      </Field>
                      {errors?.displayName && touched?.displayName ? (
                        <div className="errorMessage">
                          {errors?.displayName}
                        </div>
                      ) : null}
                    </div>
                    <div className="col-md-4">
                      <Field name="websiteUrl">
                        {() => (
                          <InputField
                            placeholder={`${t("GENERAL.WEBSITE_URL")} *`}
                            onChange={(newValue: any) =>
                              setFieldValue("websiteUrl", newValue.trim())
                            }
                            value={values.websiteUrl}
                            additionalProps={{
                              className: "textInput websiteUrlPlaceholder",
                            }}
                          />
                        )}
                      </Field>
                      {errors?.websiteUrl && touched?.websiteUrl ? (
                        <div className="errorMessage">{errors?.websiteUrl}</div>
                      ) : null}
                    </div>
                  </div>

                  {/* row 2 */}

                  <div className="row inputsRow">
                    <div className="col-md-4">
                      <Field name="email">
                        {() => (
                          <InputField
                            placeholder={`${t("GENERAL.EMAIL")} *`}
                            value={companyEmail}
                            additionalProps={{
                              className: "textInput",
                              disabled: true,
                            }}
                          />
                        )}
                      </Field>
                    </div>
                    <div className="col-md-4">
                      <div className="d-flex flex-row gap-2">
                        <div className="col-auto full-width">
                          <Field name="mobile">
                            {() => (
                              <div className="detailsDropdown mobileCodeDropdown">
                                <PhoneInput
                                  inputProps={{
                                    name: "phone",
                                    required: true,
                                  }}
                                  placeholder={t("GENERAL.MOBILE")}
                                  country={"in"}
                                  enableSearch={true}
                                  value={values.mobile}
                                  onChange={(newValue: any) =>
                                    setFieldValue("mobile", newValue)
                                  }
                                  countryCodeEditable={false}
                                />
                              </div>
                            )}
                          </Field>
                        </div>
                      </div>
                      {errors?.mobile && touched?.mobile ? (
                        <div className="errorMessage">{errors?.mobile}</div>
                      ) : null}
                    </div>
                  </div>
                  {/* row 3 */}
                  <div className="details">
                    <span>{t("GENERAL.COMPANY_LOCATION")}</span>
                  </div>
                  <div className="row">
                    <div className="col-md-12">
                      <Field name="address">
                        {() => (
                          // <InputField
                          //   placeholder={`${t("GENERAL.ADDRESS")}*`}
                          //   onChange={(newValue: any) =>
                          //     setFieldValue("address", newValue.trim())
                          //   }
                          //   value={values.address}
                          //   additionalProps={{
                          //     className: "textInput",
                          //   }}
                          // />
                          <LocationSearch
                            className={
                              "textInput addressConuntryPlaceholder fullWidthInput"
                            }
                            setLat={setLat}
                            setLon={setLon}
                            setCity={setCity}
                            setCountry={setCountry}
                            setState={setState}
                            placeholder={`${t("GENERAL.ADDRESS")} *`}
                            setFieldValue={setFieldValue}
                            values={values}
                            isAddress={editDetails?.address}
                          />
                        )}
                      </Field>
                      {errors?.address && touched?.address ? (
                        <div className="errorMessage">{errors?.address}</div>
                      ) : null}
                    </div>
                  </div>
                  {/* row 3 */}

                  <div className="row inputsRow">
                    <div className="col-md-4">
                      <Field name="country">
                        {() => (
                          <InputField
                            placeholder={`${t("GENERAL.COUNTRY")} *`}
                            value={country || values.country}
                            onChange={(newValue: any) =>
                              setFieldValue("country", newValue)
                            }
                            additionalProps={{
                              className: "textInput addressConuntryPlaceholder",
                              disabled:
                                values.address && !country ? false : true,
                            }}
                          />
                        )}
                      </Field>
                      {!country && errors?.country && touched?.country ? (
                        <div className="errorMessage">{errors?.country}</div>
                      ) : null}
                    </div>
                    <div className="col-md-4">
                      <Field name="state">
                        {() => (
                          <InputField
                            placeholder={`${t("GENERAL.STATE")} *`}
                            value={state || values.state}
                            onChange={(newValue: any) =>
                              setFieldValue("state", newValue)
                            }
                            additionalProps={{
                              className: "textInput statePlaceholder",
                              disabled: values.address && !state ? false : true,
                            }}
                          />
                        )}
                      </Field>
                      {!state && errors?.state && touched?.state ? (
                        <div className="errorMessage">{errors?.state}</div>
                      ) : null}
                    </div>
                    <div className="col-md-4">
                      <Field name="city">
                        {() => (
                          <InputField
                            placeholder={`${t("GENERAL.CITY")} *`}
                            value={city || values.city}
                            onChange={(newValue: any) =>
                              setFieldValue("city", newValue)
                            }
                            additionalProps={{
                              className: "textInput cityPlaceholder",
                              disabled: values.address && !city ? false : true,
                            }}
                          />
                        )}
                      </Field>
                      {!city && errors?.city && touched?.city ? (
                        <div className="errorMessage">{errors?.city}</div>
                      ) : null}
                    </div>
                  </div>

                  {/* row 4 */}

                  <div className="row inputsRow">
                    <div className="col-md-4">
                      <Field name="zipcode">
                        {() => (
                          <InputField
                            placeholder={`${t("GENERAL.ZIPCODE")} *`}
                            onChange={(newValue: any) =>
                              setFieldValue("zipcode", newValue.trim())
                            }
                            value={values.zipcode}
                            additionalProps={{
                              className: "textInput addressConuntryPlaceholder",
                            }}
                          />
                        )}
                      </Field>
                      {errors?.zipcode && touched?.zipcode ? (
                        <div className="errorMessage">{errors?.zipcode}</div>
                      ) : null}
                    </div>
                    {/* <div className="col-md-4">
                      <Field name="latitude">
                        {() => (
                          <InputField
                            placeholder={`${t("GENERAL.LATITUDE")}*`}
                            value={lat}
                            additionalProps={{
                              className: "textInput",
                              disabled: true,
                            }}
                          />
                        )}
                      </Field>
                    </div> */}
                    {/* <div className="col-md-4">
                      <Field name="longitude">
                        {() => (
                          <InputField
                            placeholder={`${t("GENERAL.LONGITUDE")}*`}
                            value={lon}
                            additionalProps={{
                              className: "textInput",
                              disabled: true,
                            }}
                          />
                        )}
                      </Field>
                    </div> */}
                  </div>
                  {/* row 5 Social links*/}
                  <div className="details">
                    <span>{t("GENERAL.SOCIAL_LINKS")}</span>
                  </div>
                  <div className="d-flex row inputsRow">
                    <div className="col-md-4 ">
                      <div className="d-flex flex-row gap-3 align-items-center">
                        <div>
                          <LinkedinIcon />
                        </div>
                        <div className="col-md full-width">
                          <Field name="linkedin">
                            {() => (
                              <InputField
                                placeholder={t("GENERAL.LINKEDIN")}
                                onChange={(newValue: any) =>
                                  setFieldValue("linkedin", newValue.trim())
                                }
                                value={values.linkedin}
                                additionalProps={{ className: "textInput" }}
                              />
                            )}
                          </Field>
                        </div>
                      </div>
                      {errors?.linkedin && touched?.linkedin ? (
                        <div className="col-12">
                          <div className="errorMessage">{errors?.linkedin}</div>
                        </div>
                      ) : null}
                    </div>
                    <div className="col-md-4">
                      <div className="d-flex flex-row gap-3 align-items-center">
                        <div>
                          <TwitterIcon />
                        </div>
                        <div className="col-md full-width">
                          <Field name="twitter">
                            {() => (
                              <InputField
                                placeholder={t("GENERAL.TWITTER")}
                                onChange={(newValue: any) =>
                                  setFieldValue("twitter", newValue.trim())
                                }
                                value={values.twitter}
                                additionalProps={{ className: "textInput" }}
                              />
                            )}
                          </Field>
                        </div>
                      </div>
                      {errors?.twitter && touched?.twitter ? (
                        <div className="col-12">
                          <div className="errorMessage">{errors?.twitter}</div>
                        </div>
                      ) : null}
                    </div>
                    <div className="col-md-4">
                      <div className="d-flex flex-row gap-3 align-items-center">
                        <div>
                          <FacebookIcon />
                        </div>
                        <div className="col-md full-width">
                          <Field name="facebook">
                            {() => (
                              <InputField
                                placeholder={t("GENERAL.FACEBOOK")}
                                onChange={(newValue: any) =>
                                  setFieldValue("facebook", newValue.trim())
                                }
                                value={values.facebook}
                                additionalProps={{ className: "textInput" }}
                              />
                            )}
                          </Field>
                        </div>
                      </div>
                      {errors?.facebook && touched?.facebook ? (
                        <div className="col-12">
                          <div className="errorMessage">{errors?.facebook}</div>
                        </div>
                      ) : null}
                    </div>
                  </div>

                  {/* row 5 */}

                  <div className="row inputsRow">
                    <div className="col-md-4">
                      <div className="d-flex flex-row gap-3 align-items-center">
                        <div>
                          <InstagramIcon />
                        </div>
                        <div className="col-md full-width">
                          <Field name="instagram">
                            {() => (
                              <InputField
                                placeholder={t("GENERAL.INSTAGRAM")}
                                onChange={(newValue: any) =>
                                  setFieldValue("instagram", newValue.trim())
                                }
                                value={values.instagram}
                                additionalProps={{ className: "textInput" }}
                              />
                            )}
                          </Field>
                        </div>
                      </div>
                      {errors?.instagram && touched?.instagram ? (
                        <div className="col-12">
                          <div className="errorMessage">
                            {errors?.instagram}
                          </div>
                        </div>
                      ) : null}
                    </div>
                  </div>
                </div>

                {/* owners details */}
                <div className="details-container">
                  <div className="details">
                    <span>{t("GENERAL.CONTACT_PERSON")}</span>
                  </div>
                  <div className="row inputsRow">
                    <div className="col-md-4">
                      <Field name="ownerFirstName">
                        {() => (
                          <InputField
                            placeholder={`${t("GENERAL.FNAME")}*`}
                            onChange={(newValue: any) =>
                              setFieldValue("ownerFirstName", newValue)
                            }
                            value={values.ownerFirstName}
                            additionalProps={{
                              className: "textInput fnamePlaceholder",
                            }}
                          />
                        )}
                      </Field>
                      {errors?.ownerFirstName && touched?.ownerFirstName ? (
                        <div className="errorMessage">
                          {errors?.ownerFirstName}
                        </div>
                      ) : null}
                    </div>
                    <div className="col-md-4">
                      <Field name="ownerLastName">
                        {() => (
                          <InputField
                            placeholder={`${t("GENERAL.LNAME")} *`}
                            onChange={(newValue: any) =>
                              setFieldValue("ownerLastName", newValue)
                            }
                            value={values.ownerLastName}
                            additionalProps={{
                              className: "textInput lnamePlaceholder",
                            }}
                          />
                        )}
                      </Field>
                      {errors?.ownerLastName && touched?.ownerLastName ? (
                        <div className="errorMessage">
                          {errors?.ownerLastName}
                        </div>
                      ) : null}
                    </div>
                    <div className="col-md-4">
                      <Field name="ownerDesignation">
                        {() => (
                          <InputField
                            placeholder={`${t("GENERAL.DESIGNATION")} *`}
                            onChange={(newValue: any) =>
                              setFieldValue("ownerDesignation", newValue)
                            }
                            value={values.ownerDesignation}
                            additionalProps={{
                              className: "textInput designationPlaceholder",
                            }}
                          />
                        )}
                      </Field>
                      {errors?.ownerDesignation && touched?.ownerDesignation ? (
                        <div className="errorMessage">
                          {errors?.ownerDesignation}
                        </div>
                      ) : null}
                    </div>
                  </div>
                  <div className="row inputsRow">
                    <div className="col-md-4">
                      <Field name="ownerEmail">
                        {() => (
                          <InputField
                            placeholder={`${t("GENERAL.EMAIL")} *`}
                            onChange={(newValue: any) =>
                              setFieldValue("ownerEmail", newValue)
                            }
                            value={values.ownerEmail}
                            additionalProps={{
                              className: "textInput emailPlaceholder",
                            }}
                          />
                        )}
                      </Field>
                      {errors?.ownerEmail && touched?.ownerEmail ? (
                        <div className="errorMessage">{errors?.ownerEmail}</div>
                      ) : null}
                    </div>
                    <div className="col-md-4">
                      <Field name="ownerMobile">
                        {() => (
                          <InputField
                            placeholder={`${t("GENERAL.MOBILE")}`}
                            onChange={(newValue: any) =>
                              setFieldValue("ownerMobile", newValue.trim())
                            }
                            value={values.ownerMobile}
                            additionalProps={{ className: "textInput" }}
                          />
                        )}
                      </Field>
                      {errors?.ownerMobile && touched?.ownerMobile ? (
                        <div className="errorMessage">
                          {errors?.ownerMobile}
                        </div>
                      ) : null}
                    </div>
                  </div>
                </div>
                <div className="saveBtn-Container">
                  <AssessmentButton
                    type="submit"
                    // className={`${Object.keys(errors).length ? "saveBtn-disable" : "saveBtn-enable"}`}
                    className={"saveBtn-enable"}
                    // disabled={Boolean(Object.keys(errors).length)}
                  >
                    {isSubmitting
                      ? t("GENERAL.LOADING")
                      : editProfile
                        ? t("GENERAL.UPDATE")
                        : t("GENERAL.SAVE")}
                  </AssessmentButton>
                </div>
              </div>
            </Form>
          );
        }}
      </Formik>
    </div>
  );
};
export default CreateProfileScreen;
