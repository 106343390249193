import { useTranslation } from "react-i18next";
import TestCaseRightIcon from "../../../../utils/Images/TestCaseRightIcon";
import TestCaseWrongIcon from "../../../../utils/Images/TestCaseWrongIcon";
import { convertToText } from "../../../../utils/Common/Constant";
import CodeMirror from "@uiw/react-codemirror";
import { vscodeDark } from "@uiw/codemirror-theme-vscode";
interface CodingTypeProps {
  answerData: any;
}
const CodingType: React.FC<CodingTypeProps> = ({ answerData }) => {
  const { t } = useTranslation();
  const testCases = [
    { name: "test-1", result: "Success" },
    { name: "test-2", result: "Wrong" },
    { name: "test-3", result: "Success" },
  ];

  return (
    <>
      <div className="codAnswerLabel">{t("RESPONSE_LIST.ANSWER")} :</div>
      <div className="row codeMain mt-3">
        <div className="col-lg-6 codeditor">
          <CodeMirror
            value={convertToText(answerData?.response?.answer)}
            height="400px"
            theme={vscodeDark}
            editable={false}
            readOnly={true}
          />
        </div>
        <div className="col-lg-6 testCaseWithResult">
          <div className="tabChange">
            <ul
              className="nav nav-pills mb-3 w-100"
              id="pills-tab"
              role="tablist"
            >
              <li className="nav-item w-100" role="presentation">
                <button
                  className="testCaseBtn active"
                  id="pills-home-tab"
                  data-bs-toggle="pill"
                  data-bs-target="#pills-home"
                  type="button"
                  role="tab"
                  aria-controls="pills-home"
                  aria-selected="true"
                >
                  {t("RESPONSE_LIST.TESTCASES")}
                </button>
              </li>
              <li className="nav-item w-100" role="presentation">
                <button
                  className="testCaseBtn brRight"
                  id="pills-profile-tab"
                  data-bs-toggle="pill"
                  data-bs-target="#pills-profile"
                  type="button"
                  role="tab"
                  aria-controls="pills-profile"
                  aria-selected="false"
                >
                  {t("RESPONSE_LIST.RECORDING")}
                </button>
              </li>
            </ul>
            <div className="tab-content" id="pills-tabContent">
              <div
                className="fade show active"
                id="pills-home"
                role="tabpanel"
                aria-labelledby="pills-home-tab"
                tabIndex={0}
              >
                <div className="testCaseFrame">
                  <div className="testcases">
                    {testCases?.map((list: any, index: number) => {
                      return (
                        <div
                          key={index}
                          className="testcase"
                          style={{
                            border:
                              list?.result === "Success"
                                ? "1px solid #DFFFF7"
                                : "1px solid #FFC9C9",
                          }}
                        >
                          <div className="testCaseTitle">
                            <span className="">test case</span>
                            <p>{list?.name}</p>
                          </div>
                          <div
                            style={{
                              background:
                                list?.result === "Success"
                                  ? "#DFFFF7"
                                  : "#FFC9C9",
                            }}
                            className="icon"
                          >
                            {list?.result === "Success" ? (
                              <TestCaseRightIcon />
                            ) : (
                              <TestCaseWrongIcon />
                            )}
                          </div>
                        </div>
                      );
                    })}
                  </div>
                </div>
              </div>
              <div
                className="fade"
                id="pills-profile"
                role="tabpanel"
                aria-labelledby="pills-profile-tab"
                tabIndex={1}
              >
                <div className="videoFrame">
                  <video
                    className="codingTypeVideoCss"
                    src={answerData?.response?.answerVideoUrl}
                    controls
                  >
                    {t("RESPONSE_LIST.VIDEO_DOSE_NOT_SUPPORT")}
                  </video>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default CodingType;
