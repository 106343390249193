import {
  AssessmentButton,
  Bullets,
  Pagination,
  showToast,
} from "ui-components";
import {
  copyToClipboard,
  getTimeDifference,
  manageDuplicateSkills,
} from "../../utils/Common/Constant";
import "./Invite.scss";
import { useTranslation } from "react-i18next";
import AssessListUserIcon from "../../utils/Images/AssessListUserIcon";
import { useEffect, useRef, useState } from "react";
import {
  getAssessmentDetails,
  getInvitedApplicantList,
  getRecommendationDetails,
  sendInvitationApi,
} from "../../service/Invite/Invite";
import { useNavigate, useParams } from "react-router-dom";
import NoAssessListFoundIcon from "../../utils/Images/NoAssessListFoundIcon";
import useDebounce from "../../utils/Hooks/useDebounce";
import InviteApplicant from "./InviteApplicant";
import Loader from "../../components/Loader/Loader";
import CopyVector from "../../utils/Images/CopyVector";
import BackArrow from "../../utils/Images/BackArrow";
import i18n from "../../i18nConfig";

interface IassessmentIdFromSelect {
  value: string | number | undefined;
  label: string;
}

const Invite = () => {
  const { t } = useTranslation();
  const { assessmentIdParam } = useParams();
  const navigate = useNavigate();
  const urlRef = useRef(null);
  const [assessmentDetail, setAssessmentDetail] = useState<any>(null);
  const [totalCount, setTotalCount] = useState(0);
  const [recommendationCurrentPage, setRecommendationCurrentPage] = useState(1);
  const [recommendationList, setRecommendationList] = useState([]);
  const defaultSelectAssess = { value: assessmentIdParam, label: "" };
  const [assessmentIdFromSelect] =
    useState<IassessmentIdFromSelect>(defaultSelectAssess);
  // const debounceValue = useDebounce(assessmentIdFromSelect?.label.trim(), 400);
  // const [assessmentListForSearch, setAssessmentListForSearch] = useState([]);
  // const [assessmentSearchLoading] = useState(false);
  // const [isSearchEnable, setIsSearchEnable] = useState(false);
  const defaultSelectApplicant = { value: "", label: "" };
  const [applicantIdFromSelect] = useState<IassessmentIdFromSelect>(
    defaultSelectApplicant,
  ); // setApplicantIdFromSelect
  const applicantDebounceValue = useDebounce(
    applicantIdFromSelect?.label.trim(),
    400,
  );
  const [applicantListForSearch, setApplicantListForSearch] = useState([]);
  const [inviteAppTotalCount, setInviteAppTotalCount] = useState(0);
  const [recommendatationInviteLoader, setIsRecommendatationInviteLoader] =
    useState<any>(null);
  const [inviteCurrentPage, setInviteCurrentPage] = useState(1);
  const [managePaginationRef, setManagePaginationRef] = useState<any>(null);
  const [pageSize, setPageSize] = useState(10);
  const [invitePageSize, setInvitePageSize] = useState(10);
  const recommendationRef = useRef<HTMLDivElement>(null);
  const currentLanguage = i18n.language;
  useEffect(() => {
    getAssessDetails();
  }, []);

  useEffect(() => {
    if (recommendationRef.current && managePaginationRef != null) {
      recommendationRef.current.scrollIntoView({ behavior: "smooth" });
    }
  }, [managePaginationRef]);

  // useEffect(() => {
  //   if (debounceValue) {
  //     getAssessmentListForSearch();
  //   }
  // }, [debounceValue]);

  useEffect(() => {
    getAssessDetails();
  }, [assessmentIdFromSelect?.value]);

  useEffect(() => {
    getRecommendation();
  }, [assessmentIdFromSelect?.value, recommendationCurrentPage, pageSize]);

  useEffect(() => {
    getApplicantListForSearch();
  }, [assessmentIdParam, inviteCurrentPage, invitePageSize]);

  useEffect(() => {
    if (applicantDebounceValue) {
      getApplicantListForSearch();
    }
  }, [applicantDebounceValue]);

  // get ApplicantList list for search
  async function getApplicantListForSearch() {
    const getApplicantList = await getInvitedApplicantList(
      assessmentIdParam,
      inviteCurrentPage,
      invitePageSize,
      applicantDebounceValue ?? "",
    );
    if (getApplicantList?.status === 200) {
      setInviteAppTotalCount(getApplicantList?.data?.totalCount);
      let updateData;
      if (getApplicantList?.data?.data?.length > 0) {
        updateData = (getApplicantList?.data?.data || []).map((item: any) => ({
          ...item,
          value: item?.email,
          label: item?.displayName,
          isOpen: false,
        }));
      } else {
        updateData = [];
      }

      setApplicantListForSearch(updateData);
    } else {
      showToast(getApplicantList?.customMessage, "error");
    }
  }

  // get assessment list for search
  // async function getAssessmentListForSearch() {
  //   // firstRender: boolean = false
  //   const existingTemplate = await getPublicAndCompaniesPrivateAssessment(
  //     industriesSkillId,
  //     companyId,
  //     debounceValue ?? "",
  //     1,
  //     10000,
  //   );
  //   const updateData = (existingTemplate?.data || []).map((item: any) => ({
  //     value: item?.assessmentId,
  //     label: item?.title,
  //   }));
  // if (firstRender && updateData.length > 0 && assessmentIdFromSelect?.value) {
  //   // get the id and value pair for the first render & pass in search dropdown
  //   const fetchedLabel = await getLabelByValue(
  //     updateData,
  //     assessmentIdFromSelect?.value,
  //   );
  //   setAssessmentIdFromSelect(fetchedLabel ?? defaultSelectAssess);
  //   setAssessmentSearchLoading(false);
  // }
  // setAssessmentListForSearch(updateData);
  // }

  // get recommendation
  async function getRecommendation() {
    const recommendationDetails = await getRecommendationDetails(
      assessmentIdFromSelect?.value ?? "",
      recommendationCurrentPage,
      pageSize,
    );
    if (recommendationDetails?.status === 200) {
      setRecommendationList(recommendationDetails?.data?.data);
      setTotalCount(recommendationDetails?.data?.totalCount);
    } else {
      showToast(recommendationDetails?.data?.customMessage, "error");
    }
  }

  // get assessment deatails
  async function getAssessDetails() {
    const assessDetails = await getAssessmentDetails(
      assessmentIdFromSelect?.value ?? "",
    );
    if (assessDetails?.status === 200) {
      setAssessmentDetail(assessDetails?.data);
    } else {
      showToast(assessDetails?.data?.customMessage, "error");
      if (assessDetails?.status === 409) {
        navigate(`/${currentLanguage}/404-not-found`);
      }
    }
  }

  // send invitation
  const sendInvitation = async (
    emailId: string | number,
    resetForm?: () => void,
    setSubmitting?: (data: boolean) => any,
  ) => {
    const data = {
      assessmentId: assessmentIdFromSelect?.value,
      emailId,
    };
    try {
      // Set isSubmitting to true to indicate that form submission is in progress
      setSubmitting && setSubmitting(true);
      if (emailId) {
        const sendInvitationRes = await sendInvitationApi(data);
        if (sendInvitationRes?.data?.status === 200) {
          showToast(sendInvitationRes?.data?.customMessage, "success");
          getApplicantListForSearch();
          resetForm && resetForm();
          setIsRecommendatationInviteLoader(null);
        } else {
          showToast(sendInvitationRes?.data?.customMessage, "error");
          setIsRecommendatationInviteLoader(null);
        }
      } else {
        // Handle the case where sendInvitation is undefined
        console.error("sendInvitation function is not defined");
        setIsRecommendatationInviteLoader(null);
      }
    } catch (error) {
      console.error("Error submitting form:", error);
      setIsRecommendatationInviteLoader(null);
    } finally {
      // Ensure isSubmitting is set to false, even if an error occurs during submission
      setSubmitting && setSubmitting(false);
    }
  };

  return (
    <div className="recommendationSection">
      <div className="backArrow" onClick={() => window.history.back()}>
        <BackArrow />
      </div>
      <div className="container-fluid p-0">
        <div className="row">
          <div className="col-lg-8 divisionFirst">
            <div className="recommendationSearchSec">
              {/* <div className={`${isSearchEnable ? "searchEnableSec" : ""}`}>
                <div>
                  <SearchSuggestionParent
                    options={assessmentListForSearch}
                    placeholder="Search Assessment"
                    value={assessmentIdFromSelect}
                    onChange={(getValue: any) => {
                      setAssessmentIdFromSelect(getValue);
                      setRecommendationCurrentPage(1);
                      navigate(`/assessment/invite/${getValue?.value}`);
                    }}
                    loading={assessmentSearchLoading}
                    loadingText={t("GENERAL.LOADING")}
                    iconName="searchList"
                    iconCallback={() => setIsSearchEnable(false)}
                    isStoredValueVisible={true}
                  />
                </div>
              </div> */}
              <div className="titleEnable">
                <div className="d-flex col-12 justify-content-between inviteTitleRow">
                  <h5 className="col-9">{assessmentDetail?.title ?? "-"}</h5>
                  <AssessmentButton
                    className="lightBtn"
                    onClick={() => {
                      navigate(
                        `/${currentLanguage}/assessment/reviewlist/${assessmentIdParam}`,
                      );
                    }}
                  >
                    {t("GENERAL.VIEW_RESPONSES")}
                  </AssessmentButton>
                </div>
                <div className="d-flex align-items-center">
                  <div className="link_wrap">
                    <p className="acc_style04">
                      {assessmentDetail?.isPublic
                        ? t("GENERAL.PUBLIC")
                        : t("GENERAL.PRIVATE")}
                    </p>
                  </div>
                  <span>{assessmentDetail?.assessmentType ?? "-"}</span>
                </div>
              </div>
              <div className="bulletSec">
                {(
                  manageDuplicateSkills(assessmentDetail?.assessmentSkills) ??
                  []
                ).map((skillList: any) => (
                  <Bullets
                    key={Math.random()}
                    skillName={skillList?.skillName ?? ""}
                  />
                ))}
              </div>
              <p
                className="recommendationSearchPara"
                dangerouslySetInnerHTML={{
                  __html: ` ${assessmentDetail?.description ?? ""}`,
                }}
              ></p>
              <div className="recommendationProvider">
                <div>
                  <h5>{t("INVITE.COMPANY_PROVIDER_TITLE")}</h5>
                  <h6>{assessmentDetail?.companyName ?? "-"}</h6>
                </div>
                <div>
                  <h5>{t("GENERAL.QUESTIONS")}</h5>
                  <h6>{assessmentDetail?.totalQuestions ?? "-"}</h6>
                </div>
                <div>
                  <h5>{t("GENERAL.TIME")}</h5>
                  <h6>
                    {assessmentDetail?.totalTime ?? "0"} {t("GENERAL.MINS")}
                  </h6>
                </div>
              </div>
              {assessmentDetail?.slugUrl && (
                <div className="URLCopySec">
                  <div className="urlInput" title={t("GENERAL_PUBLIC_URL")}>
                    <p ref={urlRef}>{assessmentDetail?.slugUrl}</p>
                    <AssessmentButton
                      className="lightBtn"
                      onClick={() =>
                        copyToClipboard(urlRef, assessmentDetail?.slugUrl)
                      }
                    >
                      <CopyVector />
                    </AssessmentButton>
                  </div>
                </div>
              )}
            </div>
            <div className="recommendationHeading" ref={recommendationRef}>
              <h2>{t("GENERAL.RECOMMENDATIONS")}</h2>
              {totalCount > 0 && (
                <p>
                  {t("INVITE.TOTAL_RECOMMENDATIONS")} : {totalCount}
                </p>
              )}
            </div>
            <div className="recommendationSec">
              {recommendationList?.length > 0 ? (
                recommendationList.map((list: any) => {
                  return (
                    <>
                      <div
                        className="recommendateUserProfileSec"
                        key={Math.random()}
                      >
                        <div className="recommendateUserProfile">
                          {list?.applicantImage ? (
                            <img
                              className="userDetailsIcon"
                              src={list?.applicantImage}
                              alt="userimage"
                            />
                          ) : (
                            <div className="userIcon">
                              <AssessListUserIcon />
                            </div>
                          )}
                          <div className="userDetails">
                            <h3>{list?.displayName}</h3>
                            <span>{t("INVITE.ASSESSMENT_SKILLS")}</span>
                            <div className="bulletSec">
                              {(
                                manageDuplicateSkills(list?.assessmentSkills) ??
                                []
                              ).map((skillList: any) => (
                                <Bullets
                                  key={Math.random()}
                                  skillName={skillList?.skillName ?? ""}
                                />
                              ))}
                            </div>
                            <div className="recommendateUserDate">
                              <div>
                                <h6>{t("GENERAL.DATE")}</h6>
                                <h4>
                                  {getTimeDifference(
                                    list?.submittedDate,
                                    t("GENERAL.MIN(S)"),
                                    t("GENERAL.HOUR(S)"),
                                    t("GENERAL.DAY(S)"),
                                    t("GENERAL.MONTH(S)"),
                                    t("GENERAL.YEAR(S)"),
                                    t("GENERAL.AGO"),
                                    t("GENERAL.JUST_NOW"),
                                  ) ?? "-"}
                                </h4>
                              </div>
                              <div>
                                <h6>{t("CREATE_ASSESSMENT.SCORE")} </h6>
                                <h4>{list?.finalScore}%</h4>
                              </div>
                            </div>
                          </div>
                          <div className="userInviteBtn">
                            <AssessmentButton
                              className="lightBtn"
                              onClick={() => {
                                setIsRecommendatationInviteLoader(list?.email);
                                sendInvitation(list?.email);
                              }}
                              disabled={
                                recommendatationInviteLoader == list?.email
                              }
                            >
                              {recommendatationInviteLoader == list?.email ? (
                                <>
                                  {t("GENERAL.LOADING")}
                                  <Loader />
                                </>
                              ) : (
                                t("GENERAL.INVITE")
                              )}
                            </AssessmentButton>
                          </div>
                        </div>
                      </div>
                    </>
                  );
                })
              ) : (
                <div className="noRecommendationFound">
                  <div className="text-center">
                    <NoAssessListFoundIcon />
                    <h1>{t("INVITE.NO_RECOMMENDATIONS_FOUND")}</h1>
                  </div>
                </div>
              )}
            </div>
            <div className="row">
              <div className="col-md-12">
                <Pagination
                  className="pagination-bar"
                  currentPage={recommendationCurrentPage}
                  totalCount={totalCount || 0}
                  pageSize={pageSize}
                  onPageChange={(page: number) => {
                    setRecommendationCurrentPage(page);
                    setManagePaginationRef(Math.random());
                  }}
                  ofLabel={t("GENERAL.OF")}
                  resultLabel={t("GENERAL.RESULTS")}
                  setPageSize={setPageSize}
                />
              </div>
            </div>
          </div>
          <div className="col-lg-4 divisionSecond">
            <InviteApplicant
              sendInvitation={sendInvitation}
              applicantListForSearch={applicantListForSearch}
              setApplicantListForSearch={setApplicantListForSearch}
              inviteAppTotalCount={inviteAppTotalCount}
              inviteCurrentPage={inviteCurrentPage}
              setInviteCurrentPage={setInviteCurrentPage}
              invitePageSize={invitePageSize}
              getApplicantListForSearch={getApplicantListForSearch}
              setInvitePageSize={setInvitePageSize}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Invite;
