import { useTranslation } from "react-i18next";
import ManualReviewLogo from "../../../utils/Images/ManualReviewLogo";
import SmartAssessLogo from "../../../utils/Images/SmartAssessLogo";
import CorrectnessIcon from "../../../utils/Images/CorrectnessIcon";
import VisualAnalysisIcon from "../../../utils/Images/VisualAnalysisIcon";
import VoiceAnalysisIcon from "../../../utils/Images/VoiceAnalysisIcon";
import { convertDate } from "../../../utils/Common/Constant";

interface SideContent {
  assessmentDetail: any;
  totalSelected: number;
}
const SideContent: React.FC<SideContent> = ({
  assessmentDetail,
  totalSelected,
}) => {
  const { t } = useTranslation();
  // Find the evaluation types by their type property
  const smartAiEvaluation = assessmentDetail?.evaluationTypes?.find(
    (type: any) => type.type === "smartAi",
  );
  const manualEvaluation = assessmentDetail?.evaluationTypes?.find(
    (type: any) => type.type === "manual",
  );
  return (
    <>
      <div className="col-sm-12 col-md-3 col-lg-2">
        <div className="skillsContent">
          <div className="skillsDetailsContent">
            <h4>{t("GENERAL.PUBLISHED")}</h4>
            <h3>{convertDate(assessmentDetail?.createdAt, false, true)}</h3>
          </div>
          <div className="skillsDetailsContent">
            <h4>{t("GENERAL.QUESTIONS")}</h4>
            <h3>{assessmentDetail?.totalQuestions}</h3>
          </div>
          <div className="skillsDetailsContent">
            <h4>{t("GENERAL.TIME")}</h4>
            <h3>
              {assessmentDetail?.totalTime} {t("GENERAL.MINS")}
            </h3>
          </div>
          <div className="skillsDetailsContent">
            <h4>{t("GENERAL.EXPERIENCE")}</h4>
            <h3>
              {assessmentDetail?.minimumExperience}-
              {assessmentDetail?.maximumExperience} {t("GENERAL.YEAR_WITH_S")}
            </h3>
          </div>
          <div className="skillsDetailsContent">
            <h4>{t("GENERAL.OPEN_POSITIONS")}</h4>
            <h3>{assessmentDetail?.totalPositions}</h3>
          </div>
          <div className="skillsDetailsContent">
            <h4>{t("GENERAL.SELECTED")}</h4>
            <h3>{totalSelected}</h3>
          </div>
          <div className="skillsDetailsContent">
            <h4>{t("GENERAL.CUTOFF")}</h4>
            <h3>{assessmentDetail?.cutOff}%</h3>
          </div>
          <div className="skillsDetailsContent evalutionSec">
            <h4>{t("GENERAL.EVALUATION")}</h4>
            <div className="d-flex">
              <ManualReviewLogo
                color={manualEvaluation?.attributes ? "#35d4ae" : "#B3B3B3"}
              />
              <h3 className="w-100">{t("GENERAL.MANUAL_REVIEW")}</h3>
            </div>
            <div className="d-flex">
              <SmartAssessLogo
                color={
                  smartAiEvaluation?.attributes?.aiAnalysis
                    ? "#35d4ae"
                    : "#B3B3B3"
                }
              />
              <h3 className="w-100">{t("GENERAL.SMARTASSESS_AI")}</h3>
            </div>
            <ul>
              <div className="d-flex">
                <CorrectnessIcon
                  color={
                    smartAiEvaluation?.attributes?.aiAnalysis
                      ? "#35d4ae"
                      : "#B3B3B3"
                  }
                />
                <h3 className="w-100">{t("RESPONSE_LIST.AI_ANALYSIS")}</h3>
              </div>
              <div className="d-flex">
                <VisualAnalysisIcon
                  color={
                    smartAiEvaluation?.attributes?.visualAnalysis
                      ? "#35d4ae"
                      : "#B3B3B3"
                  }
                />
                <h3 className="w-100">
                  {t("CREATE_ASSESSMENT.LABEL_VISUAL_ANALYSIS")}
                </h3>
              </div>
              <div className="d-flex">
                <VoiceAnalysisIcon
                  color={
                    smartAiEvaluation?.attributes?.audioAnalysis
                      ? "#35d4ae"
                      : "#B3B3B3"
                  }
                />
                <h3 className="w-100">
                  {t("CREATE_ASSESSMENT.LABEL_VOICE_ANALYSIS")}
                </h3>
              </div>
            </ul>
          </div>
        </div>
      </div>
    </>
  );
};

export default SideContent;
