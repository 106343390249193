import { useTranslation } from "react-i18next";
import "./Footer.scss";
import HeaderLogo from "../../utils/Images/HeaderLogo";
const Footer = () => {
  const { t } = useTranslation();
  return (
    <div className="footer">
      <div className="logo">
        <HeaderLogo />
      </div>
      <div className="middle">
        <p>© 2024, Evluate &nbsp;| &nbsp;{t("GENERAL.VERSION")}: 0.1</p>
      </div>

      <div className="base">
        <button className="getSupport"> {t("GET_SUPPORT")}</button>
        <button className="reportBug"> {t("REPORT_BUG")}</button>
      </div>
    </div>
  );
};

export default Footer;
