import { useTranslation } from "react-i18next";
import { Navigate } from "react-router-dom";

// Company flow (submit)
export function PublicCompanyRoute({ children }: any) {
  const { i18n } = useTranslation();
  const currentLanguage = i18n.language;
  const token = localStorage.getItem("companyToken");
  // const tempUniqueId = localStorage.getItem("tempUniqueId");
  const isAuthenticated = Boolean(token);
  return isAuthenticated ? (
    // <Navigate to={`/${currentLanguage}/assessment/scope/${tempUniqueId}`} />
    <Navigate to={`/${currentLanguage}/assessment/list`} />
  ) : (
    <>{children}</>
  );
}

// Company flow (submit)
export function PrivateCompanyRoute({ children }: any) {
  const { i18n } = useTranslation();
  const currentLanguage = i18n.language;
  const token = localStorage.getItem("companyToken");
  const isAuthenticated = Boolean(token);
  return isAuthenticated ? (
    <>{children}</>
  ) : (
    <Navigate to={`/${currentLanguage}/company-login`} />
  );
}
