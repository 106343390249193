import ManualScoreIcon from "../../../../utils/Images/ManualScoreIcon";
import { useTranslation } from "react-i18next";
import { AssessmentButton, RangeSelector } from "ui-components";
import ResponseVideoTypeIcon from "../../../../utils/Images/ResponseVideoTypeIcon";
interface ManualScore {
  handleRangeValue: (value: number, setFn: any) => void;
  setFn: any;
  rangeValue: number;
  handleManualReview: any;
  isVideoType: boolean;
  handleManualClick: any;
  isManualEvaluation: any;
  isNotified: boolean;
}
const ManualScore: React.FC<ManualScore> = ({
  handleRangeValue,
  setFn,
  rangeValue,
  handleManualReview,
  isVideoType,
  handleManualClick,
  isManualEvaluation,
  isNotified,
}) => {
  const { t } = useTranslation();
  return (
    <div
      className={`manualContainer ${!isVideoType && "justify-content-start"} mt-3`}
    >
      {isVideoType && (
        <div
          onClick={handleManualClick}
          className={`ResponseVideoTypeIcon ${!isNotified && isManualEvaluation?.isEvaluation ? "mt-5" : ""}`}
        >
          <ResponseVideoTypeIcon
            color={
              !isManualEvaluation?.isEvaluation && !isNotified
                ? "#35D4AE"
                : "#9E9E9E"
            }
          />
        </div>
      )}
      <div className="manualRangeContainer">
        <div className="manualScore">
          <div className="ManualScoreIconDiv">
            <ManualScoreIcon
              height="24"
              width="24"
              color={
                !isManualEvaluation?.isEvaluation && !isNotified
                  ? "#35D4AE"
                  : "#9E9E9E"
              }
            />{" "}
            <span>{t("RESPONSE_LIST.MANUAL_SCORE")}</span>
          </div>

          <div
            className={`manualCount ${!isManualEvaluation?.isEvaluation && !isNotified ? "activeManualScore" : "inActiveManualScore"}`}
          >
            <h3>
              {t("RESPONSE_LIST.SCORE")} - <span>{rangeValue}%</span>
            </h3>
          </div>
        </div>
        <div
          className={`manualRage ${!isManualEvaluation?.isEvaluation && !isNotified ? "activeManualSection" : "inactiveManualSection"}`}
        >
          <RangeSelector
            id="two6547bdgtrb4564"
            label={""}
            minLabel={"0"}
            maxLabel={100}
            step={1}
            min={0}
            max={100}
            value={rangeValue}
            setValue={(value) => {
              handleRangeValue(value, setFn);
            }}
            disabled={
              !!isManualEvaluation?.isEvaluation || (isNotified && true)
            }
          />
        </div>
        {!isManualEvaluation?.isEvaluation && !isNotified ? (
          <div className="rangeSaveBtn">
            <AssessmentButton onClick={handleManualReview}>
              {t("GENERAL.SAVE")}
            </AssessmentButton>
          </div>
        ) : (
          isNotified && (
            <div className="rangeSaveBtn">
              <AssessmentButton onClick={handleManualReview} disabled={true}>
                {t("GENERAL.SAVE")}
              </AssessmentButton>
            </div>
          )
        )}
      </div>
    </div>
  );
};

export default ManualScore;
