import { useTranslation } from "react-i18next";
import {
  DragIcon,
  EssayTypeQuestion,
  MultiTypeQuestion,
  SingleTypeQuestion,
  TemplateRow,
} from "ui-components";
import "./ReviewQuestions.scss";
import { useContext, useEffect, useState } from "react";
import { setSessionStorageItem } from "../../../../../utils/SessionStorageMethod/setSessionStorageItem";
import { getSessionStorageItem } from "../../../../../utils/SessionStorageMethod/getSessionStorageItem";
import Accordion from "../Accordion/Accordion";
import { systemPreferenceContext } from "../../../../../context/SystemPreference/SystemPreference";
import { sortArr2ByArr1 } from "../../../../../utils/Common/Constant";

const ReviewQuestions: React.FunctionComponent = () => {
  const { t } = useTranslation();
  const { preferenceData } = useContext(systemPreferenceContext);
  const [items, setItems] = useState<any[]>(
    getSessionStorageItem("draggedItems") || [],
  );
  const [droppedIndex, setDroppedIndex] = useState<number | null>(
    getSessionStorageItem("droppedIndex") || null,
  );
  const [removingIndex, setRemovingIndex] = useState<number | null>(null);
  const [isDragging, setIsDragging] = useState(false);

  let lastIndex = 0;

  const handleDragStart = (event: any, itemIndex: any) => {
    event.dataTransfer.setData("text/plain", itemIndex);
    setIsDragging(true);
  };

  const handleDragOver = (event: any) => {
    event.preventDefault();
  };

  const ownPreview = getSessionStorageItem("ownPreview") || [];
  const createQuestionData = getSessionStorageItem("createQuestionData") || [];

  // Check if the items exist before accessing their properties
  const ownPreviewSkillId = ownPreview.length > 0 ? ownPreview[0].skillId : 1;
  const createQuestionDataId =
    createQuestionData.length > 0 ? createQuestionData[0].id : 1;

  useEffect(() => {
    const getDifficulties = () => {
      const skillData = getSessionStorageItem("ownPreview") ?? [];
      let updateSkill = [...skillData];
      updateSkill = updateSkill?.map((item: any) => ({
        ...item,
        skillName: item?.title ?? item?.skillName,
        skillIcon: item?.icon ?? item?.skillIcon,
        skillLevel: item?.difficultyLevel,
        type: "template",
      }));
      updateSkill = updateSkill.flatMap((item: any) => {
        if (item?.selectedDifficultyLevels) {
          return Object.entries(item.selectedDifficultyLevels).map(
            ([level, count], ind) => ({
              ...item,
              id: item?.id + ind,
              skillLevel: level,
              totalQuestions: count,
            }),
          );
        } else {
          return item;
        }
      });
      const customQuestionData = getSessionStorageItem("createQuestionData");
      const combinedSkill = [...updateSkill, ...(customQuestionData ?? [])];
      const finalSequenceArr =
        sortArr2ByArr1(getSessionStorageItem("draggedItems"), combinedSkill) ??
        [];
      setItems(finalSequenceArr ?? []);
    };
    getDifficulties();
  }, [ownPreviewSkillId, createQuestionDataId, preferenceData]);

  const handleDrop = (event: any, dropIndex: any) => {
    event.preventDefault();
    setIsDragging(false);
    const draggedItemIndex = event.dataTransfer.getData("text/plain");
    const newItems = [...items];
    const [draggedItem] = newItems.splice(draggedItemIndex, 1);
    newItems.splice(dropIndex, 0, draggedItem);
    setItems(newItems);
    setSessionStorageItem("draggedItems", newItems);
    setDroppedIndex(dropIndex);
    setSessionStorageItem("droppedIndex", dropIndex);

    // Set a delay before removing the dropped item
    setTimeout(() => {
      setRemovingIndex(draggedItemIndex);
      // Clear the dropping effect after the transition duration
      setTimeout(() => {
        setDroppedIndex(null);
        setSessionStorageItem("droppedIndex", null);
        setRemovingIndex(null);
      }, 300); // Assuming 300ms is the duration of your transition effect
    }, 300); // Adjust the delay based on your preference
    // event.target.classList.remove("over");
  };

  const calculateTransitionDelay = (index: number): string => {
    return `${index * 50}ms`; // Adjust the delay based on your preference
  };
  const renderView = (item: any, index: number) => {
    switch (item?.type?.toLowerCase() ?? item?.questionType?.toLowerCase()) {
      case "template":
        return (
          <TemplateRow
            title={item?.skillName}
            icon={item?.skillIcon}
            questionsCount={item?.totalQuestions ?? item?.questionsCount}
            difficulty={item?.skillLevel}
            templateSecStyle={{ flex: 1 }}
            indiAssessTime={`${
              isNaN(item?.totalQuestions * 3)
                ? isNaN(item?.questionsCount * 3)
                  ? 0
                  : item?.questionsCount * 3
                : item?.totalQuestions * 3
            } ${t("GENERAL.MINS")}`}
            questionLabel={t("GENERAL.QUESTIONS")}
            difficultyLabel={t("GENERAL.DIFFICULTY")}
            timeLabel={t("GENERAL.TIME")}
            questionType={item?.questionType}
          />
        );

      case "single":
        return (
          <Accordion
            index={index}
            type={t("GENERAL.SINGLE")}
            timeLabel={t("GENERAL.TIME")}
            timeValue={`${item?.timeLimit} ${t("GENERAL.MINS")}`}
            data={item}
          >
            <SingleTypeQuestion
              answerLabel={t("GENERAL.ANSWER")}
              isAccordion={true}
              data={item}
            />
          </Accordion>
        );

      case "multiple":
        return (
          <Accordion
            index={index}
            type={t("GENERAL.MULTIPLE")}
            timeLabel={t("GENERAL.TIME")}
            timeValue={`${item?.timeLimit} ${t("GENERAL.MINS")}`}
            data={item}
          >
            <MultiTypeQuestion
              answerLabel={t("GENERAL.ANSWER")}
              isAccordion={true}
              data={item}
            />
          </Accordion>
        );

      case "essay":
        return (
          <Accordion
            index={index}
            type={t("GENERAL.ESSAY")}
            timeLabel={t("GENERAL.TIME")}
            timeValue={`${item?.timeLimit} ${t("GENERAL.MINS")}`}
            data={item}
          >
            <EssayTypeQuestion
              answerLabel={t("GENERAL.ANSWER")}
              isAccordion={true}
              data={item}
            />
          </Accordion>
        );
      case "video":
        return (
          <Accordion
            index={index}
            type="Video"
            timeLabel="Time"
            timeValue={`${item?.timeLimit} ${t("GENERAL.MINS")}`}
            data={item}
          >
            <EssayTypeQuestion
              answerLabel={t("GENERAL.ANSWER")}
              isAccordion={true}
              data={item}
            />
          </Accordion>
        );

      default:
        break;
    }
  };
  // handleQuestionRange used for question range example :- 1-5,5-10 something like that
  const handleQuestionRange = (type: string, totalQuestions: number) => {
    let questionRange;
    if (type === "template") {
      questionRange =
        totalQuestions === 1
          ? lastIndex + 1
          : `${lastIndex + 1} - ${lastIndex + totalQuestions}`;
      lastIndex += totalQuestions;
    } else {
      questionRange = lastIndex + 1;
      lastIndex++;
    }
    return questionRange;
  };

  return (
    <div className="assessmentQuesSection">
      {items?.length ? (
        <>
          <h4 className="questionHeading">{t("GENERAL.SCOPE")}</h4>
          <div className={`dragDrop ${isDragging ? "dragging" : ""}`}>
            <div
              className={`dragDropContent ${isDragging ? "dragging" : ""}`}
              //  onDragOver={handleDragOver}
            >
              {(items ?? [])?.map((item: any, index) => (
                <div
                  key={index}
                  className={`item item-transition ${
                    index === droppedIndex ? "dropped-item" : ""
                  } ${index === removingIndex ? "removing-item" : ""}`}
                  style={{
                    transitionDelay: calculateTransitionDelay(index),
                  }}
                  draggable
                  onDragStart={(e) => handleDragStart(e, index)}
                  onDragOver={handleDragOver}
                  // onDragEnter={handleDragEnter}
                  // onDragLeave={handleDragLeave}
                  onDrop={(e) => handleDrop(e, index)}
                >
                  <div className="setQues">
                    <div className="numbersSec">
                      {item?.questionCount}
                      <h6>
                        {handleQuestionRange(
                          item?.type,
                          item?.totalQuestions || item?.questionsCount || 1,
                        )}
                      </h6>
                    </div>
                    <div className="techQuestionsContent">
                      {renderView(item, index)}
                    </div>
                    <div className="dragIconSec">
                      <DragIcon />
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </>
      ) : (
        <p className="notice-text">{t("ERROR.SELECT_SCOPE")}</p>
      )}
    </div>
  );
};

export default ReviewQuestions;
