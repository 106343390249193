import { handleError } from "../../utils/Common/Constant";
import apiClient from "../Connection/Connection";

// to get GetAssessment Details By AssessementId
export const getAssessmentDetailsByAssessementId = async (
  assessmentId: string,
) => {
  try {
    return (
      await apiClient.get(
        `company/companyGetAssessmentDetailsByAssessementId?assessmentId=${assessmentId}`,
      )
    )?.data;
  } catch (error) {
    handleError(error);
  }
};
