import { uploadVideoMultiPart } from "smartassess-api-lib";

export const videoUploader = async (
  blob: any,
  path?: string,
  fileExtension?: string,
) => {
  const fileData = [];
  fileData.push(blob);

  const jsonData = {
    file: fileData,
    path,
    startUploadEndPoint: "s3Upload/startUpload",
    getPresignedEndPoint: "s3Upload/generatePresigned",
    completeUploadEndPoint: "s3Upload/completeUpload",
    fileExtension: fileExtension ? fileExtension : "webm",
  };
  const imageData = await uploadVideoMultiPart(jsonData);
  return imageData?.data?.data?.ImageUrl;
};
