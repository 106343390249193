import {
  AssessmentButton,
  AssessmentCheckboxRadio,
  Bullets,
  Pagination,
  SearchTypeSelect,
  TemplateSearch,
  TypeSelect,
  showToast,
} from "ui-components";
import "./CreatedAssessmentList.scss";
import { useTranslation } from "react-i18next";
import AssesmentListingIcon from "../../../utils/Images/AssesmentListingIcon";
import { useEffect, useRef, useState } from "react";
import AssessmentListingDeleteIcon from "../../../utils/Images/AssessmentListingDeleteIcon";
import AssessmentListingInviteIcon from "../../../utils/Images/AssessmentListingInviteIcon";
// import AssessmentListingEditIcon from "../../../utils/Images/AssessmentListingEditIcon";
import { getCreatedAssessList } from "../../../service/CreatedAssessmentListAndReview/CreatedAssessmentListAndReview";
import {
  convertDate,
  copyToClipboard,
  getLabelByValue,
  handleKeyPressSearch,
  manageDuplicateSkills,
} from "../../../utils/Common/Constant";
import {
  deleteAssessment,
  getDefaultSkill,
} from "../../../service/Scope/Scope";
import { useNavigate, useSearchParams } from "react-router-dom";
import NoAssessListFoundIcon from "../../../utils/Images/NoAssessListFoundIcon";
import ConfirmPopup from "./ConfirmPopup/ConfirmPopup";
import CopyVector from "../../../utils/Images/CopyVector";
import useDebounce from "../../../utils/Hooks/useDebounce";
import RecommendationIcon from "../../../utils/Images/RecommendationIcon";
import SmartAssessLogo from "../../../utils/Images/SmartAssessLogo";
function CreatedAssessmentList() {
  const { t, i18n } = useTranslation();
  // Get current language
  const currentLanguage = i18n.language;
  const [showFilter] = useState(true);
  const [createdAssessmentList, setCreatedAssessmentList] = useState([]);
  const [getSkillList, setGetSkillList] = useState([]);
  const [totalCount, setTotalCount] = useState(0);
  const [showDelete, setShowDelete] = useState<boolean>(false);
  const [deleteAssessmentTitle, setDeleteAssessmentTitle] = useState(null);
  const [selectAssessmentId, setSelectAssessmentId] = useState(null);
  const [managePaginationRef, setManagePaginationRef] = useState<any>(null);
  const [pageSize, setPageSize] = useState(10);
  const [isResetDisable, setIsResetDisable] = useState(false);
  const createdAssessPaginaitonRef = useRef<HTMLDivElement>(null);
  const defaultStatus = [
    { label: t("GENERAL.ALL"), value: "All" },
    { label: t("GENERAL.DRAFT"), value: "Draft" },
    { label: t("GENERAL.PUBLISHED"), value: "Published" },
    { label: t("GENERAL.CLOSED"), value: "Closed" },
  ];
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const status = searchParams.get("st");
  const skill = searchParams.get("sk");
  const hasResponse = searchParams.get("hasResponse") ?? "both";
  const hasReview = searchParams.get("hasReview") ?? "both";
  const selected = searchParams.get("selected") ?? "both";
  const searchParam = searchParams.get("search");
  const page = searchParams.get("page") ? Number(searchParams.get("page")) : 1;
  const urlRef = useRef(null);
  const timeoutRef = useRef<any>(null);
  const defaultSelectSkills = { value: skill, label: "" };
  const [skillIdFromSelect, setSkillIdFromSelect] =
    useState<any>(defaultSelectSkills);
  const debounceSkillSearchValue = useDebounce(
    skillIdFromSelect?.label?.trim(),
    400,
  );
  const industriesSkillId = localStorage.getItem("industryId") ?? "";
  const companyId = localStorage.getItem("companyId") ?? "";
  useEffect(() => {
    status ||
    skill ||
    hasResponse !== "both" ||
    hasReview !== "both" ||
    selected !== "both" ||
    searchParam?.length
      ? setIsResetDisable(false)
      : setIsResetDisable(true);
  }, [status, skill, hasResponse, hasReview, selected, searchParam]);
  useEffect(() => {
    if (createdAssessPaginaitonRef.current && managePaginationRef != null) {
      createdAssessPaginaitonRef.current.scrollIntoView({ behavior: "smooth" });
    }
  }, [managePaginationRef]);
  useEffect(() => {
    industriesSkillId !== "null" && getSkills(true);
  }, []);
  useEffect(() => {
    industriesSkillId !== "null" && getSkills();
  }, [debounceSkillSearchValue]);

  useEffect(() => {
    handleFilter({ type: "skill", value: skillIdFromSelect?.value });
  }, [skillIdFromSelect?.value]);
  useEffect(() => {
    // Function to handle debouncing
    const debounceFunction = () => {
      if (timeoutRef.current !== null) {
        clearTimeout(timeoutRef.current as any); // Cast to any
      }
      timeoutRef.current = setTimeout(getAssessmentList, 500);
    };
    // Trigger debounce function when search parameter changes
    debounceFunction();
    // Cleanup function to clear timeout when component unmounts
    return () => clearTimeout(timeoutRef.current);
  }, [location.search, searchParam, pageSize]);

  // to get assessment list
  async function getAssessmentList() {
    const data = {
      companyId: companyId,
      search: searchParam?.trim() ?? "",
      filterOptions: {
        status: status ?? "All",
        skillId: skill ?? "",
        hasResponse: hasResponse,
        reviewPending: hasReview,
        selected: selected,
      },
      currentPage: page ?? 1,
      pageSize,
    };
    const assessmentRes = await getCreatedAssessList(data);
    if (assessmentRes?.data?.status === 200) {
      setCreatedAssessmentList(
        assessmentRes?.data?.data?.data.map((assessment: any) => {
          return { ...assessment, moreOption: false };
        }) ?? [],
      );
      setTotalCount(assessmentRes?.data?.data?.totalCount);
    } else {
      showToast(assessmentRes?.data?.customMessage, "error");
    }
  }
  // to get skills
  async function getSkills(firstLoad: boolean = false) {
    const industriesSkill = await getDefaultSkill(
      industriesSkillId,
      debounceSkillSearchValue,
      1,
      100000,
    );
    const updateData = (industriesSkill?.data || []).map((item: any) => ({
      value: item?.skillId,
      label: item?.skillName,
      ...item,
    }));
    setGetSkillList(updateData);
    if (firstLoad) {
      setTitle(updateData);
    }
  }
  // to set the title
  const setTitle = async (updatedDataSet: any) => {
    const fetchedLabel = await getLabelByValue(
      updatedDataSet,
      skillIdFromSelect?.value ?? "",
    );
    setSkillIdFromSelect(fetchedLabel ?? defaultSelectSkills);
  };
  // open handle extra options
  // const handleExtraOptions = (assessmentId: string) => {
  //   setCreatedAssessmentList((prevState: any) => {
  //     const updatedItems = prevState.map((item: any) => {
  //       if (item.assessmentId === assessmentId) {
  //         return {
  //           ...item,
  //           moreOption: !item.moreOption,
  //         };
  //       } else {
  //         return {
  //           ...item,
  //           moreOption: false,
  //         };
  //       }
  //     });
  //     return updatedItems;
  //   });
  // };

  // to delete assessment
  async function deleteAssessmentById() {
    const deleteAssessmentResponse = await deleteAssessment(selectAssessmentId);
    if (deleteAssessmentResponse?.data?.status === 200) {
      showToast(deleteAssessmentResponse?.data?.customMessage, "success");
      getAssessmentList();
      setSelectAssessmentId(null);
      setShowDelete(false);
      setDeleteAssessmentTitle(null);
    } else {
      showToast(deleteAssessmentResponse?.data?.customMessage, "error");
    }
  }
  // clear all filters
  const clearAllFilter = () => {
    setSkillIdFromSelect({ value: "", label: "" });
    // Constructing the final URL
    const url = `/${currentLanguage}/assessment/list`;
    // Navigating to the final URL
    navigate(url);
  };
  // handle filters
  const handleFilter = ({ type, value }: any) => {
    // Constructing the URL parameters object
    const currentLanguage = i18n.language || "en";
    let params = {
      st: status,
      sk: skill,
      hasResponse: hasResponse,
      hasReview: hasReview,
      selected: selected,
      page: page,
      search: searchParam,
    };
    switch (type) {
      case "status":
        params = { ...params, st: value, page: page };
        break;
      case "skill":
        params = { ...params, sk: value, page: page };
        break;
      case "hasResponse":
        params = { ...params, hasResponse: value, page: page };
        break;
      case "hasReview":
        params = { ...params, hasReview: value, page: page };
        break;
      case "selected":
        params = { ...params, selected: value, page: page };
        break;
      case "page":
        params = { ...params, page: value };
        break;
      case "search":
        params = { ...params, search: value, page: 1 };
        break;
    }

    // Constructing the URL based on the parameters object
    const urlParams = new URLSearchParams();
    Object.entries(params).forEach(([key, value]: any) => {
      if (value) {
        urlParams.append(key, value);
      }
    });

    // Constructing the final URL
    const url = `/${currentLanguage}/assessment/list${urlParams.toString() ? `?${urlParams.toString()}` : ""}`;

    // Navigating to the final URL
    navigate(url);
  };

  return (
    <div className="CreatedAssessmentList">
      <div className="CreatedAssessmentListTitle">
        <h2>{t("CREATED_ASSESSMENT_LIST_AND_REVIEW.ASSESSMENT")}</h2>
      </div>
      <div
        className={`AssessmentListSearchSec ${showFilter ? "" : "addMargin"}`}
        ref={createdAssessPaginaitonRef}
      >
        <TemplateSearch
          onChange={(e) => {
            handleFilter({ type: "search", value: e });
          }}
          placeholder={t("GENERAL.SEARCH_ASSESSMENT")}
          handleKeyPress={handleKeyPressSearch}
          iconName="searchList"
          value={searchParam ?? ""}
        />

        {/* <div onClick={() => setShowFilter(!showFilter)}>
          <div
            className={`FilterIcon cursorPointer ${showFilter ? "activeFilterBorder" : ""}`}
          >
            <AssessListFilterIcon color={`${showFilter ? "#35d4ae" : ""}`} />
          </div>
        </div> */}
      </div>
      {showFilter && (
        <div className={`${showFilter && "filterClicked"}`}>
          <div className="typeSelectSec w-100">
            <div className="w-100">
              <label htmlFor="" className="mainLabelFilter">
                {t("GENERAL.FILTERS")}
              </label>
              <TypeSelect
                options={defaultStatus}
                placeholder={t("GENERAL.STATUS")}
                value={status ?? ""}
                onChange={(getValue: any) => {
                  handleFilter({ type: "status", value: getValue });
                }}
                loadingText={t("GENERAL.LOADING")}
                className={`${!status ? "statusAssessList" : ""}`}
              />
            </div>
            <div className="w-100">
              <label htmlFor="" className="mainLabelFilter"></label>
              <SearchTypeSelect
                options={getSkillList}
                placeholder={t("GENERAL.SKILL")}
                value={skillIdFromSelect}
                onChange={(getValue: any) => {
                  setSkillIdFromSelect(getValue);
                }}
                loadingText={t("GENERAL.LOADING")}
                className="skillAssessList"
                liIconName="icon"
                outSideClickCallBack={() => {
                  setTitle(getSkillList);
                }}
              />
            </div>
          </div>

          <div className="w-75">
            <label htmlFor="" className="mainLabelFilter">
              {t("GENERAL.HAS_RESPONSE")}
            </label>
            <div className="d-flex responseSecFilter">
              <div
                className="d-flex cursorPointer rtlGap"
                onClick={() => {
                  handleFilter({ type: "hasResponse", value: "yes" });
                }}
              >
                <AssessmentCheckboxRadio
                  name="hasResponse"
                  type="radio"
                  checked={hasResponse == "yes"}
                />
                <p className="radioCheckLabel">{t("GENERAL.YES")}</p>
              </div>
              <div
                className="d-flex cursorPointer rtlGap"
                onClick={() => {
                  handleFilter({ type: "hasResponse", value: "no" });
                }}
              >
                <AssessmentCheckboxRadio
                  name="hasResponse"
                  type="radio"
                  checked={hasResponse == "no"}
                />{" "}
                <p className="radioCheckLabel">{t("GENERAL.NO")}</p>
              </div>
              <div
                className="d-flex cursorPointer rtlGap"
                onClick={() => {
                  handleFilter({ type: "hasResponse", value: "both" });
                }}
              >
                <AssessmentCheckboxRadio
                  name="hasResponse"
                  type="radio"
                  checked={hasResponse == "both"}
                />{" "}
                <p className="radioCheckLabel">{t("GENERAL.BOTH")}</p>
              </div>
            </div>
          </div>
          <div className="w-75">
            <label htmlFor="" className="mainLabelFilter">
              {t("CREATED_ASSESSMENT_LIST_AND_REVIEW.REVIEW_PENDING")}
            </label>
            <div className="d-flex responseSecFilter">
              <div
                className="d-flex cursorPointer rtlGap"
                onClick={() => {
                  handleFilter({ type: "hasReview", value: "yes" });
                }}
              >
                <AssessmentCheckboxRadio
                  type="radio"
                  checked={hasReview == "yes"}
                />{" "}
                <p className="radioCheckLabel">{t("GENERAL.YES")}</p>
              </div>
              <div
                className="d-flex cursorPointer rtlGap"
                onClick={() => {
                  handleFilter({ type: "hasReview", value: "no" });
                }}
              >
                <AssessmentCheckboxRadio
                  type="radio"
                  checked={hasReview == "no"}
                />{" "}
                <p className="radioCheckLabel">{t("GENERAL.NO")}</p>
              </div>
              <div
                className="d-flex cursorPointer rtlGap"
                onClick={() => {
                  handleFilter({ type: "hasReview", value: "both" });
                }}
              >
                <AssessmentCheckboxRadio
                  type="radio"
                  checked={hasReview == "both"}
                />{" "}
                <p className="radioCheckLabel">{t("GENERAL.BOTH")}</p>
              </div>
            </div>
          </div>
          <div className="w-75">
            <div className="manageClearAll">
              <label htmlFor="" className="mainLabelFilter">
                {t("CREATED_ASSESSMENT_LIST_AND_REVIEW.SELECTED")}
              </label>
              {/* <label
                htmlFor=""
                className="mainLabelFilter clearText"
                onClick={() => clearAllFilter()}
              >
                {t("GENERAL.RESET")}
              </label> */}
              <AssessmentButton
                className="lightBtn mainLabelFilter filterBtn"
                onClick={() => clearAllFilter()}
                disabled={isResetDisable}
              >
                {t("GENERAL.RESET")}
              </AssessmentButton>
            </div>
            <div className="d-flex responseSecFilter topMargin">
              <div
                className="d-flex cursorPointer rtlGap"
                onClick={() => {
                  handleFilter({ type: "selected", value: "yes" });
                }}
              >
                <AssessmentCheckboxRadio
                  type="radio"
                  checked={selected == "yes"}
                />{" "}
                <p className="radioCheckLabel">{t("GENERAL.YES")}</p>
              </div>
              <div
                className="d-flex cursorPointer rtlGap"
                onClick={() => {
                  handleFilter({ type: "selected", value: "no" });
                }}
              >
                <AssessmentCheckboxRadio
                  type="radio"
                  checked={selected == "no"}
                />
                <p className="radioCheckLabel">{t("GENERAL.NO")}</p>
              </div>
              <div
                className="d-flex cursorPointer rtlGap"
                onClick={() => {
                  handleFilter({ type: "selected", value: "both" });
                }}
              >
                <AssessmentCheckboxRadio
                  type="radio"
                  checked={selected == "both"}
                />{" "}
                <p className="radioCheckLabel">{t("GENERAL.BOTH")}</p>
              </div>
            </div>
          </div>
        </div>
      )}

      <div className="AssessmentListContentSec">
        {totalCount > 0 && (
          <p className="totalContent">
            {t("CREATED_ASSESSMENT_LIST_AND_REVIEW.TOTAL_ASSESSMENTS")} :{" "}
            {totalCount}
          </p>
        )}
        {createdAssessmentList.length > 0 ? (
          createdAssessmentList.map((list: any, index: any) => {
            const isAIEnabled = list?.evaluationType.filter((item: any) =>
              item.type === "smartAi" ? item : null,
            );
            return (
              <div
                className="AssessmentListCardSec"
                key={Math.random() + index}
              >
                <div className="AssessmentListCard">
                  <div
                    className="AssessmentListCardImg cursorPointer"
                    onClick={() => {
                      navigate(
                        `/${currentLanguage}/assessment/reviewlist/${list?.assessmentId}`,
                      );
                    }}
                  >
                    <AssesmentListingIcon />
                    <h4>{list?.status ?? "-"}</h4>
                    <h6>{convertDate(list?.createdAt, false, true) ?? "-"}</h6>
                  </div>
                  <div className="AssessmentListCardDetails">
                    <div className="ListCardDetailsTitleSec">
                      <div className="ListCardDetailsTitle">
                        <div className="listItemSec">
                          <div className="ListCardTitleHeading">
                            <div>
                              <div className="clipBoard">
                                <h3
                                  title={list?.title ?? ""}
                                  className="cursorPointer"
                                  ref={urlRef}
                                  onClick={() => {
                                    navigate(
                                      `/${currentLanguage}/assessment/reviewlist/${list?.assessmentId}`,
                                    );
                                  }}
                                >
                                  {list?.title}{" "}
                                </h3>
                                {list?.slugUrl && (
                                  <div
                                    className="listCopyIcon"
                                    onClick={() =>
                                      copyToClipboard(
                                        urlRef,
                                        list?.slugUrl,
                                        t("INVITE.COPY_SUCCESS"),
                                      )
                                    }
                                    title={t("GENERAL_PUBLIC_URL")}
                                  >
                                    <CopyVector color="#35D4AE" />
                                  </div>
                                )}
                              </div>
                              <div className="d-flex align-items-center">
                                <div className="link_wrap">
                                  <p className="acc_style04">
                                    {list?.isPublic ? "Public" : "Private"}
                                  </p>
                                </div>
                                <span>{list?.assessmentType}</span>
                              </div>
                            </div>
                            {list?.status == "published" ||
                            list?.status == "Published" ? (
                              <div className="d-flex">
                                <div
                                  onClick={() => {
                                    navigate(
                                      `/${currentLanguage}/assessment/invite/${list?.assessmentId}`,
                                    );
                                  }}
                                  className="cursorPointer recommendationIcon"
                                  title={t("GENERAL.RECOMMENDATIONS")}
                                >
                                  <RecommendationIcon />
                                </div>
                                <div
                                  className="cursorPointer"
                                  title={t("GENERAL.INVITE")}
                                  onClick={() => {
                                    navigate(
                                      `/${currentLanguage}/assessment/invite/${list?.assessmentId}`,
                                    );
                                  }}
                                >
                                  <AssessmentListingInviteIcon />
                                </div>
                              </div>
                            ) : list?.status == "draft" ||
                              list?.status == "Draft" ? (
                              <>
                                <div className="d-flex">
                                  <div
                                    className="cursorPointer"
                                    title={t("GENERAL.DELETE")}
                                    onClick={() => {
                                      setSelectAssessmentId(list?.assessmentId);
                                      if (list?.assessmentId) {
                                        setShowDelete(true);
                                        setDeleteAssessmentTitle(list?.title);
                                      }
                                    }}
                                  >
                                    <AssessmentListingDeleteIcon />
                                  </div>
                                </div>
                              </>
                            ) : (
                              <></>
                            )}
                          </div>
                          <div className="bulletSec">
                            {(
                              manageDuplicateSkills(list?.assessmentSkills) ||
                              []
                            ).map((skillList: any) => (
                              <Bullets
                                key={Math.random()}
                                skillName={skillList?.skillName ?? ""}
                              />
                            ))}
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="ListContentSkillsSec">
                      <div className="ListContentSkills decreaseGap">
                        <div className="w-100">
                          <div className="w-100">
                            <p>{t("GENERAL.APPLICANT")}</p>
                            <h5>{list?.totalApplicaitons ?? "-"}</h5>
                          </div>
                          <div>
                            <p>{t("GENERAL.QUESTIONS")}</p>
                            <h5>{list?.totalQuestions ?? "-"}</h5>
                          </div>
                        </div>
                        <div className="w-100">
                          <div className="w-100">
                            <p>{t("GENERAL.RESPONSES")}</p>
                            <h5>{list?.totalResponses ?? "-"}</h5>
                          </div>
                          <div>
                            <p>
                              {t("CREATED_ASSESSMENT_LIST_AND_REVIEW.SELECTED")}
                            </p>
                            <h5>{list?.totalSelected ?? "-"}</h5>
                          </div>
                        </div>
                        <div className="w-100">
                          <div className="w-100">
                            <p>{t("GENERAL.REVIEWED")}</p>
                            <h5>{list?.reviewed ?? "-"}</h5>
                          </div>
                          <div>
                            <p>{t("GENERAL.CREDITS")}</p>
                            <h5>{"-"}</h5>
                          </div>
                        </div>
                        <div className="w-100">
                          <div className="w-100">
                            <p>{t("CREATE_ASSESSMENT.TIME")}</p>
                            <h5>
                              {list?.totalTime ?? "-"} {t("GENERAL.MINS")}
                            </h5>
                          </div>
                        </div>
                      </div>
                      <div className="RightSideArrowSec">
                        {isAIEnabled[0]?.type === "smartAi" && (
                          <SmartAssessLogo />
                        )}
                        <AssessmentButton
                          onClick={() => {
                            list?.status == "Draft"
                              ? navigate(
                                  `/${currentLanguage}/assessment/template/${list?.assessmentId}?edited=true`,
                                )
                              : navigate(
                                  `/${currentLanguage}/assessment/reviewlist/${list?.assessmentId}`,
                                );
                          }}
                          className="whiteBtn"
                        >
                          {list?.status == "Draft"
                            ? t("GENERAL.EDIT")
                            : t("GENERAL.VIEW")}
                        </AssessmentButton>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            );
          })
        ) : (
          <div className="noListFound">
            <div className="text-center">
              <NoAssessListFoundIcon />
              <h1>{t("INVITE.NO_ASSESSMENT_FOUND")}</h1>
              {/* <AssessmentButton
                onClick={() => {
                  navigate(`/${currentLanguage}/assessment/create-profile`);
                }}
                className="whiteBtn"
              >
                {t("CREATE_ASSESSMENT.TITLE")}
              </AssessmentButton> */}
            </div>
          </div>
        )}
      </div>

      <div className="row">
        <div className="col-md-12">
          <Pagination
            className="pagination-bar"
            currentPage={page ?? 1}
            totalCount={totalCount || 0}
            pageSize={pageSize}
            onPageChange={(page: number) => {
              handleFilter({ type: "page", value: page });
              setManagePaginationRef(Math.random());
            }}
            ofLabel={t("GENERAL.OF")}
            resultLabel={t("GENERAL.RESULTS")}
            setPageSize={setPageSize}
          />
        </div>
      </div>
      {/* confirm popup for delete */}
      {showDelete && (
        <ConfirmPopup
          showDelete={showDelete}
          label={`${t("CREATED_ASSESSMENT_LIST.CONFIRM_DELETE_START")} ${deleteAssessmentTitle}?`}
          yesCallback={() => deleteAssessmentById()}
          noCallback={() => {
            setShowDelete(false);
            setDeleteAssessmentTitle(null);
          }}
          isCrossBtn={true}
          handleClose={() => {
            setShowDelete(false);
            setDeleteAssessmentTitle(null);
          }}
        />
      )}
    </div>
  );
}

export default CreatedAssessmentList;
