import { useTranslation } from "react-i18next";
import { getReportedQuestionList } from "../../../service/Scope/Scope";
import { useEffect, useState } from "react";

interface Props {
  assessmentId?: any;
  applicantId?: any;
}

const ReportedQuestionsList = ({ assessmentId, applicantId }: Props) => {
  const { t } = useTranslation();
  const [reportedQuestionsList, setReportedQuestionsList] = useState<any[]>([]);

  useEffect(() => {
    getReportedQuestionListApi();
  }, []);

  // Function to get all reported questions
  const getReportedQuestionListApi = async () => {
    try {
      const res = await getReportedQuestionList(assessmentId, applicantId);
      setReportedQuestionsList(res?.data?.data || []);
    } catch (error) {
      console.error(error);
    }
  };

  if (reportedQuestionsList.length === 0) {
    return null;
  }

  return (
    <div>
      <div className="assessResponseHead mt-5">
        <div className="assessResponseHeadContent">
          <h3>{t("GENERAL.REPORTED_QUESTIONS")}</h3>
        </div>
      </div>
      <div className="responseReviewQuestion mt-3">
        {reportedQuestionsList.map((item: any, index: number) => (
          <div className={"assessResponseList"} key={index}>
            <div className="assessQuestion">
              <div className="questionDetails">
                <div className="typeStatus justify-content-between ">
                  <div className="bulletSec">
                    <div className="questionSkillName">
                      {item?.isCustomQuestion
                        ? t("GENERAL.CUSTOM_QUESTION")
                        : `${item?.skillName} - ${item?.difficultyLevel}`}
                    </div>
                    <p className="d-flex">
                      {t("GENERAL.TYPE")} :
                      <span className="answerType">{item?.questionType}</span>
                    </p>
                  </div>
                  <div>
                    <span className="reportedBy">
                      {t("GENERAL.REPORTED_BY")} : {item?.reportedby}
                    </span>
                  </div>
                </div>
              </div>
              <div className="d-flex gap-2">
                <p>{index + 1}.</p>
                <p
                  dangerouslySetInnerHTML={{
                    __html: ` ${item?.question}`,
                  }}
                />
              </div>
              <hr />
              <div className="reportedText-sec">
                <span className="reportedTextHeading">
                  {t("GENERAL.REPORTED_TEXT")}
                </span>
                <br />
                <span className="reportedText">{item?.comment}</span>
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default ReportedQuestionsList;
