import { AssessmentButton, InputField, showToast } from "ui-components";
import CompanyOnboardWrapper from "../../../components/CompanyOnboard/CompanyOnboardWrapper";
import "./LoginSignup.scss";
import { Field, Form, Formik } from "formik";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import * as Yup from "yup";
import { getOtp } from "../../../service/Scope/Scope";
import { loginUserData } from "../../../utils/Common/Constant";
const SignupScreen = () => {
  const { t, i18n } = useTranslation();
  // Get current language
  const currentLanguage = i18n.language;
  const navigate: any = useNavigate();

  // login User Schema
  const loginUserSchema = Yup.object().shape({
    email: Yup.string()
      .matches(/^[^\s@]+@[^\s@]+\.[^\s@]+$/, t("ERROR.INVALID_EMAIL_ADDRESS"))
      .required(t("ERROR.EMAIL_REQUIRED")),
  });

  // login applicant
  const handleApplicantSignup = async (
    value: any,
    resetForm?: () => void,
    setSubmitting?: (data: boolean) => any,
  ) => {
    const data = {
      email: value?.email?.toLowerCase(),
    };
    try {
      setSubmitting && setSubmitting(true);
      const getCompanyOtp = await getOtp(data);
      if (getCompanyOtp?.status === 200) {
        showToast(getCompanyOtp?.customMessage, "success");
        resetForm && resetForm();
        sessionStorage.setItem("leftTime", "60");
        navigate(`/${currentLanguage}/verify-otp`, { state: data });
      }
    } catch (error) {
      console.error("Error submitting form:", error);
    } finally {
      // Ensure isSubmitting is set to false, even if an error occurs during submission
      setSubmitting && setSubmitting(false);
    }
  };
  const onSignInCLick = () => {
    navigate("/");
  };
  return (
    <CompanyOnboardWrapper
      lowerSubmitText1={t("GENERAL.ALREADY_ACCOUNT")}
      lowerSubmitText2={t("GENERAL.SIGN_IN")}
      signInUpClick={onSignInCLick}
      isGoogleSignBtn={true}
    >
      <Formik
        initialValues={loginUserData}
        validationSchema={loginUserSchema}
        onSubmit={(value, { resetForm, setSubmitting }) => {
          handleApplicantSignup(value, resetForm, setSubmitting);
        }}
      >
        {({ errors, touched, values, setFieldValue, isSubmitting }) => {
          return (
            <div className="companyLogin">
              <Form>
                <div className="userSec">
                  <div className="userContent">
                    <div className="heading-div">
                      <h4 className="userHeadingApp">
                        {t("GENERAL.REGISTER")}
                      </h4>
                    </div>
                    <Field name="email">
                      {() => (
                        <InputField
                          placeholder={`${t("GENERAL.EMAILADDRESS")} *`}
                          onChange={(newValue: any) =>
                            setFieldValue("email", newValue.trim())
                          }
                          value={values.email}
                          additionalProps={{ className: "textInput" }}
                        />
                      )}
                    </Field>
                    {errors?.email && touched?.email ? (
                      <div className="errorMessage">{errors?.email}</div>
                    ) : null}
                    <AssessmentButton
                      className="submitBtn"
                      type="submit"
                      disabled={isSubmitting}
                    >
                      {isSubmitting
                        ? t("GENERAL.LOADING")
                        : t("GENERAL.GET_OTP")}
                    </AssessmentButton>
                  </div>
                </div>
              </Form>
            </div>
          );
        }}
      </Formik>
    </CompanyOnboardWrapper>
  );
};

export default SignupScreen;
