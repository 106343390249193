import { useTranslation } from "react-i18next";
import CircularProgressBar from "../CircularProgressBar/CircularProgressBar";
import "./SkillScoreCard.scss";

interface Props {
  skillTitle?: string;
  questionType?: string;
  scorePercentage?: number;
  onScoreCardClick?: () => void;
  selected?: boolean;
  totalQuestions?: number;
  totalScoreTabQuestions?: number;
  isCategoryCard?: boolean;
  categoryName?: string;
}

const SkillScoreCard = ({
  skillTitle,
  questionType,
  scorePercentage,
  onScoreCardClick,
  selected = false, // Default to false
  totalQuestions,
  totalScoreTabQuestions,
  isCategoryCard,
  categoryName,
}: Props) => {
  const { t } = useTranslation();
  return (
    <div>
      <div
        className={`skill-square ${selected && "selected"}`} // Add class based on selected prop
        onClick={onScoreCardClick}
      >
        <div className="square-heading">
          <div className={`${categoryName && "category-strip"}`}>
            <div className="questionNo">
              <span title={categoryName}>
                {categoryName ? categoryName : "\u00A0"}
              </span>
            </div>
          </div>
          <div className="first-heading">
            <span title={skillTitle}>
              {skillTitle === "All" ? "Total Score" : skillTitle}
            </span>
          </div>
          <div className="second-heading">
            <span>{questionType || "\u00A0"} </span>
          </div>
          <circle />
        </div>
        <div>
          <div className="circular-progress">
            <CircularProgressBar
              percent={scorePercentage || 0}
              selected={selected}
            />
          </div>
        </div>
      </div>
      {!isCategoryCard && (
        <div className="questionNo">
          <p>
            {t("GENERAL.QUESTIONS")} :{" "}
            {skillTitle === "All" ? totalScoreTabQuestions : totalQuestions}
          </p>
        </div>
      )}
    </div>
  );
};

export default SkillScoreCard;
