import { ReactNode } from "react";
import HeaderLogo from "../../utils/Images/HeaderLogo";
import "./CompanyOnboardWrapper.scss";
import { useTranslation } from "react-i18next";
import GoogleIcon from "../../utils/Images/GoogleIcon";
interface props {
  children?: ReactNode;
  lowerSubmitText1?: string;
  lowerSubmitText2?: string;
  signInUpClick?: any;
  isGoogleSignBtn?: boolean;
}
const CompanyOnboardWrapper: React.FC<props> = ({
  children,
  lowerSubmitText1,
  lowerSubmitText2,
  signInUpClick,
  isGoogleSignBtn,
}) => {
  const { t } = useTranslation();
  return (
    <div className="container-fluid company-onboard-wrapper">
      <div className="row no-gutters">
        <div className="col-md-6 justify-content-left ">
          <div className="left-partition" />
        </div>
        <div className="col-md-6 right-partition d-flex flex-column justify-content-center">
          <div className="right-content">
            <div className="logo text-center mb-4 ">
              <HeaderLogo />
            </div>
            {children}
            <p
              className={`text-center mt-3 account_signup ${!isGoogleSignBtn && "account_signupCreatePassword"}`}
            >
              {lowerSubmitText1}
              <span onClick={signInUpClick}> {lowerSubmitText2}</span>
            </p>
            {isGoogleSignBtn && (
              <>
                <div className="divider my-3">{t("GENERAL.OR")}</div>
                <div className="googleSignIn">
                  <GoogleIcon />
                  <span>{t("GENERAL.CONTINUE_WITH_GOOGLE")}</span>
                </div>
              </>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default CompanyOnboardWrapper;
