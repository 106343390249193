import React, { useState, useRef, useCallback } from "react";
import ReactCrop, { PixelCrop } from "react-image-crop";
import "react-image-crop/dist/ReactCrop.css";
import { Modal, Button } from "react-bootstrap";
import "./CropModal.scss";
interface ImageCropPopupProps {
  show: boolean;
  file: File;
  onClose: () => void;
  onCropComplete: (croppedFile: Blob) => void;
}

const ImageCropPopup: React.FC<ImageCropPopupProps> = ({
  show,
  file,
  onClose,
  onCropComplete,
}) => {
  const [crop, setCrop] = useState<any>({ aspect: 1 });
  const [completedCrop, setCompletedCrop] = useState<PixelCrop | null>(null);
  const imageRef = useRef<HTMLImageElement | null>(null);

  const onComplete = useCallback((crop: PixelCrop) => {
    setCompletedCrop(crop);
  }, []);

  const handleCrop = () => {
    const image = imageRef.current;
    if (image && completedCrop && completedCrop.width && completedCrop.height) {
      const canvas = document.createElement("canvas");
      const scaleX = image.naturalWidth / image.width;
      const scaleY = image.naturalHeight / image.height;
      canvas.width = completedCrop.width;
      canvas.height = completedCrop.height;
      const ctx = canvas.getContext("2d");

      if (ctx) {
        ctx.drawImage(
          image,
          completedCrop.x * scaleX,
          completedCrop.y * scaleY,
          completedCrop.width * scaleX,
          completedCrop.height * scaleY,
          0,
          0,
          completedCrop.width,
          completedCrop.height,
        );

        canvas.toBlob((blob) => {
          if (blob) {
            onCropComplete(blob);
          }
        });
      }
    }
  };

  return (
    <Modal show={show} onHide={onClose} className="cropModal">
      <Modal.Header closeButton>
        <Modal.Title>Crop Image</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <ReactCrop
          crop={crop}
          onChange={(newCrop: any) => setCrop(newCrop)}
          onComplete={onComplete}
        >
          <img ref={imageRef} src={URL.createObjectURL(file)} alt="Crop" />
        </ReactCrop>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={onClose}>
          Close
        </Button>
        <Button variant="primary" onClick={handleCrop}>
          Crop
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default ImageCropPopup;
