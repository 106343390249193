import {
  ApproxTimeHead,
  AssessmentButton,
  ReviewSteps,
  showToast,
  TitleHead,
} from "ui-components";
import { useTranslation } from "react-i18next";
import "./Head.scss";
import { useContext, useEffect, useRef, useState } from "react";
import { setSessionStorageItem } from "../../../../../utils/SessionStorageMethod/setSessionStorageItem";
import { getSessionStorageItem } from "../../../../../utils/SessionStorageMethod/getSessionStorageItem";
import { AssessmentJsonContext } from "../../../../../context/JsonContext/JsonContext";
import ComponentIds from "../../../../../utils/Json/componentIds.json";
import {
  deleteAssessmentTempData,
  getRoleCentricRender,
} from "../../../../../service/Scope/Scope";
import { Field, useFormikContext } from "formik";
import {
  findValuesForKey,
  isDisable,
  isHide,
} from "../../../../../utils/Common/Constant";
import { useNavigate, useParams } from "react-router-dom";
export interface HeadProps {
  handlePrevious: (count: number) => void;
  currentStep: number;
  errors?: any;
  touched?: any;
}

const Head: React.FunctionComponent<HeadProps> = ({
  handlePrevious,
  currentStep,
  errors = null,
  touched = null,
}) => {
  const formikContext = useFormikContext();
  const { setFieldValue } = formikContext || {};
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { tempUniqueId } = useParams();
  const { i18n } = useTranslation();
  // Get current language
  const currentLanguage = i18n.language;
  const contentRef = useRef<HTMLDivElement>(null);
  // const editedParams = new URLSearchParams(location.search).get("edited");
  const renderRouteName = "template";
  const [titleHeadContent, setTitleHeadContent] = useState(
    getSessionStorageItem("titleHeadContent"),
  );
  // const [type, setType] = useState<string>(
  //   getSessionStorageItem("Type") || "Hiring",
  // );
  const [isSticky, setIsSticky] = useState(false);
  const [countQuestion, setCountQuestion] = useState<number>(
    getSessionStorageItem("totalQuestionCount"),
  );

  const [totalTime, setTotalTime] = useState<number>(
    getSessionStorageItem("totalQuestionTime"),
  );

  const { state, dispatch } = useContext(AssessmentJsonContext) ?? {
    state: { renderJson: {} },
    dispatch: () => {},
  };

  const reviewStepData = [
    { stepCount: 1, stepLabel: t("GENERAL.TEMPLATE") },
    { stepCount: 2, stepLabel: t("GENERAL.BUILD") },
    { stepCount: 3, stepLabel: t("GENERAL.CUSTOM") },
    { stepCount: 4, stepLabel: t("GENERAL.SETTINGS") },
    { stepCount: 5, stepLabel: t("GENERAL.REVIEW") },
  ];

  useEffect(() => {
    (async () => {
      const getJsonData = await getRoleCentricRender(renderRouteName);
      dispatch({
        type: "UPDATE_CENTRIC_RENDER_JSON",
        payload: getJsonData,
      });
    })();
  }, []);

  useEffect(() => {
    const handleScroll = () => {
      const stickyOffset = -160;
      if (
        contentRef.current &&
        contentRef?.current?.getBoundingClientRect().top <= stickyOffset
      ) {
        setIsSticky(true);
      } else {
        setIsSticky(false);
      }
    };
    window.addEventListener("scroll", handleScroll, true);

    return () => {
      window.removeEventListener("scroll", handleScroll);
      // observer.disconnect(); // Disconnect the observer on unmount
    };
  }, []);

  // Run this effect whenever the session storage is updated
  useEffect(() => {
    // setType(getSessionStorageItem("Type") ?? "Hiring");
    setTitleHeadContent(getSessionStorageItem("titleHeadContent"));
    if (setFieldValue) {
      setFieldValue("type", getSessionStorageItem("Type") || "");
      setFieldValue(
        "titleHeadContent",
        getSessionStorageItem("titleHeadContent") || "",
      );
    }
  }, [
    getSessionStorageItem("Type"),
    getSessionStorageItem("titleHeadContent"),
  ]);

  // Your existing code...
  useEffect(() => {
    const intervalId = setInterval(() => {
      const { countQuestion, totalTime } = totalQuestionCount();
      setCountQuestion(countQuestion);
      setTotalTime(totalTime);
    }, 100);

    return () => clearInterval(intervalId); // Clear interval on component unmount
  }, []);
  const titleHeadChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const inputValue = e.target.value;
    const capitalizedValue =
      inputValue.charAt(0).toUpperCase() + inputValue.slice(1);
    setTitleHeadContent(capitalizedValue);
    setSessionStorageItem("titleHeadContent", capitalizedValue.trim() ?? "");

    if (setFieldValue) {
      setFieldValue("titleHeadContent", capitalizedValue);
    }
  };
  // count number of question in our assessment
  const totalQuestionCount = () => {
    const totalQuestion = (assessmentSkills: any) => {
      if (assessmentSkills[0]?.questionNo) {
        const totalQuestions = assessmentSkills.reduce(
          (accumulator: any, currentValue: any) =>
            accumulator + currentValue?.questionNo,
          0,
        );
        return totalQuestions;
      } else {
        const totalQuestions = assessmentSkills.reduce(
          (accumulator: any, currentValue: any) =>
            accumulator + currentValue?.questionsCount,
          0,
        );
        return totalQuestions;
      }
    };
    let mergeData: any = [];
    const skill = getSessionStorageItem("ownPreview") ?? [];
    const existing =
      getSessionStorageItem("existingPreview")?.subjectFieldSet ?? [];
    if (skill?.length) {
      mergeData = [...skill];
    } else {
      mergeData = [...existing];
    }
    const customQuestionSet = getSessionStorageItem("createQuestionData");
    const customQuestionTime = customQuestionSet?.reduce(
      (accumulator: any, currentValue: any) =>
        accumulator + currentValue?.timeLimit,
      0,
    );

    const countCustomQuestion =
      getSessionStorageItem("createQuestionData")?.length ?? 0;
    const countSkill = totalQuestion(mergeData) ?? 0;
    setSessionStorageItem(
      "totalQuestionCount",
      countSkill + countCustomQuestion,
    );
    setSessionStorageItem(
      "totalQuestionTime",
      countSkill * 3 + (isNaN(customQuestionTime) ? 0 : customQuestionTime),
    );
    return {
      countQuestion: countSkill + countCustomQuestion,
      totalTime:
        countSkill * 3 + (isNaN(customQuestionTime) ? 0 : customQuestionTime),
    };
  };
  const handleCreateNewAssessment = async () => {
    const deleteData = await deleteAssessmentTempData(
      localStorage.getItem("companyId") ?? "",
      localStorage.getItem("tempUniqueId") ?? "",
    );
    if (deleteData?.data?.status === 200) {
      sessionStorage.clear();
      localStorage.removeItem("tempUniqueId");
      showToast(deleteData?.data?.customMessage, "success");
      navigate(`/${currentLanguage}/assessment/template/${tempUniqueId}`);
    }
  };
  return (
    <div className="headContentSec" ref={contentRef}>
      <div className="assessmentHeading">
        <h2>{t("CREATE_ASSESSMENT.TITLE")}</h2>
        <AssessmentButton onClick={() => handleCreateNewAssessment()}>
          {t("CREATE_ASSESSMENT.CREATE_NEW")}
        </AssessmentButton>
      </div>
      <div className="saveDraftSection">
        {isHide(
          findValuesForKey(
            state?.renderJson,
            renderRouteName,
            ComponentIds.scope.typeSelectKey,
          ),
        ) && (
          <>
            <Field name="type">
              {() => (
                <div className="assessmentHiring">
                  <h2>{t("GENERAL.HIRING")}</h2>
                </div>
              )}
            </Field>
            {errors?.type && touched?.type ? (
              <div className="errorMessage">{errors?.type}</div>
            ) : null}
          </>
        )}
        <div className="saveDraftSec">
          <span>{t("GENERAL.TOTAL_QUESTION")} : </span>
          {isHide(
            findValuesForKey(
              state?.renderJson,
              renderRouteName,
              ComponentIds.scope.deleteBtnKey,
            ),
          ) && (
            <div className="saveQuesCount">
              <b>{isNaN(countQuestion) ? 0 : countQuestion}</b>
            </div>
          )}
        </div>
      </div>
      <div className={`${isSticky && "fixedHeader"}`}>
        <div className={isSticky ? "sticky" : ""}>
          <div className="headTitleSec">
            {isHide(
              findValuesForKey(
                state?.renderJson,
                renderRouteName,
                ComponentIds.scope.assessmentTitleKey,
              ),
            ) && (
              <>
                <TitleHead
                  disable={isDisable(
                    findValuesForKey(
                      state?.renderJson,
                      renderRouteName,
                      ComponentIds.scope.assessmentTitleKey,
                    ),
                  )}
                  headTitle={titleHeadContent}
                  titleHeadChange={titleHeadChange}
                  placeHolder={t("ASSESSMENT_TITLE")}
                />
                {errors?.titleHeadContent && touched?.titleHeadContent ? (
                  <div className="errorMessage">{errors?.titleHeadContent}</div>
                ) : null}
              </>
            )}
            <div
              className="stickyTimeAdd"
              title={`${t("GENERAL.TOTAL_QUESTION")} : ${
                isNaN(countQuestion) ? 0 : countQuestion
              }`}
            >
              <ApproxTimeHead
                approxTimeLabel={t("GENERAL.TOTAL_QUESTION")}
                approxTimeValue={`${isNaN(countQuestion) ? 0 : countQuestion}`}
              />
            </div>
            <div
              className="timeMinWid"
              title={`${t("CREATE_ASSESSMENT.TIME")} : ${
                isNaN(totalTime) ? 0 : totalTime
              } ${t("GENERAL.MINS")}`}
            >
              <ApproxTimeHead
                approxTimeLabel={t("CREATE_ASSESSMENT.TIME")}
                approxTimeValue={`${
                  isNaN(totalTime) ? 0 : totalTime
                } ${t("GENERAL.MINS")}`}
              />
            </div>
          </div>
        </div>
        <ReviewSteps
          data={reviewStepData}
          handlePrevious={handlePrevious}
          currentStep={currentStep}
        />
      </div>
    </div>
  );
};

export default Head;
